import React from 'react';

import './IconUtils.scss';

interface DashoffsetAnimatorProps {
  strokeDasharray?: [number, number];
  strokeDashoffset: number;
  duration: number;
}
export const DashoffsetAnimator = ({
  duration,
  strokeDasharray = [24, 24],
  strokeDashoffset,
  children,
}: React.PropsWithChildren<DashoffsetAnimatorProps>) => {
  const child = React.Children.only(children);

  if (React.isValidElement(child)) {
    return React.cloneElement(child, {
      ...child.props,
      strokeDashoffset: strokeDashoffset,
      strokeDasharray: strokeDasharray,
      style: {
        transition: `stroke-dashoffset ${duration}ms ease-in-out`,
      },
    });
  }

  return null;
};
