import {
  addMonths,
  endOfMonth,
  getDay,
  getDaysInMonth,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isSameDay,
  isSunday,
  set,
  startOfMonth,
  subMonths,
} from 'date-fns';

import { createId, range } from 'domain/utils';
import React from 'react';

export const EventColors = [
  '#8dd3c7',
  '#ffffb3',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
  '#ccebc5',
  '#ffed6f',
];

export type CalendarEventMs = {
  startDate: number;
  endDate: number;
  name: string;
  user: string;
};
export type CalendarEvent<T> = {
  startDate: Date;
  endDate: Date;
  name: string;
  user?: string;
  data?: T;
};

export enum EventDatePosition {
  Between = 'event-between',
  Start = 'event-start',
  End = 'event-end',
  OneDayEvent = 'one-day-event',
}

export type CalendarEntryEvent<T> = {
  event: CalendarEvent<T>;
  key: string;
  color: string;
  showName: boolean;
  position?: EventDatePosition;
};
export type CalendarEntry<T> = {
  date: Date;
  events: CalendarEntryEvent<T>[];
};

const isCalendarEntry = <T>(obj: any): obj is CalendarEntry<T> => {
  return 'date' in obj && 'events' in obj;
};

const isCalendarEntryEvent = <T>(obj: any): obj is CalendarEntryEvent<T> => {
  return 'key' in obj && 'color' in obj;
};

const getMonthStartWeekDay = (date: Date) => {
  const firstMonthDay = startOfMonth(date);
  return getDay(firstMonthDay);
};

const getMonthEndWeekDay = (date: Date) => {
  const lastMonthDay = endOfMonth(date);
  return getDay(lastMonthDay);
};

const getMonthDays = (date: Date) => {
  return getDaysInMonth(date);
};

const getMonthDaysArray = (date: Date) => {
  const days = getMonthDays(date);
  return range(days, 1);
};

const getPreviousMonthDays = (date: Date) => {
  const prevMonth = subMonths(date, 1);
  return getMonthDays(prevMonth);
};

const getNextMonthDays = (date: Date) => {
  const nextMonth = addMonths(date, 1);
  return getMonthDays(nextMonth);
};

const getPreviousMonthVisibleDays = (date: Date) => {
  const prevMonthDays = getPreviousMonthDays(date);
  const firstCurrMonthDay = getMonthStartWeekDay(date);
  const daysArr = range(prevMonthDays, 1);
  return daysArr.slice(prevMonthDays - firstCurrMonthDay, prevMonthDays);
};

const getNextMonthVisibleDays = (date: Date) => {
  const lastCurrMonthDay = getMonthEndWeekDay(date);
  const daysArr = range(7, 1);
  return daysArr.slice(0, 6 - lastCurrMonthDay);
};

const getDateEvents = (date: Date, events: CalendarEvent<any>[]) => {
  return events.filter(
    (evt) => getEventPositionInDate(date, evt) !== undefined
  );
};

const getEventPositionInDate = (date: Date, event: CalendarEvent<any>) => {
  const isBetween =
    isBefore(date, event.endDate) && isAfter(date, event.startDate);
  const isFirstDay = isSameDay(date, event.startDate);
  const isLastDay = isSameDay(date, event.endDate);
  const sameDay =
    isSameDay(event.startDate, event.endDate) &&
    isSameDay(event.startDate, date);

  if (sameDay) {
    return EventDatePosition.OneDayEvent;
  }
  if (isFirstDay) {
    return EventDatePosition.Start;
  }

  if (isLastDay) {
    return EventDatePosition.End;
  }

  if (isBetween) {
    return EventDatePosition.Between;
  }

  return undefined;
};

const getRenderDates = (date: Date) => {
  const prevMonth = subMonths(date, 1);
  const nextMonth = addMonths(date, 1);

  const prevDays = getPreviousMonthVisibleDays(date);
  const currDays = getMonthDaysArray(date);
  const nextDays = getNextMonthVisibleDays(date);

  const prevDates = prevDays.map((day) => {
    const _date = set(prevMonth, { date: day });
    return _date;
  });
  const nextDates = nextDays.map((day) => {
    const _date = set(nextMonth, { date: day });
    return _date;
  });
  const currDates = currDays.map((day) => {
    const _date = set(date, { date: day });
    return _date;
  });

  return prevDates.concat(currDates).concat(nextDates);
};

const getEventColor = (i: number) => {
  return EventColors[i % EventColors.length];
};

const buildRenderEntries = <T extends any>(
  dates: Date[],
  events: CalendarEvent<T>[]
): CalendarEntry<T>[] => {
  const processedEvents: Omit<CalendarEntryEvent<T>, 'date' | 'showName'>[] =
    events.map((evt, i) => ({
      event: evt,
      key: createId(`calendar_event_`),
      color: getEventColor(i),
    }));

  return dates.map((date) => {
    const dateEvents = getDateEvents(date, events);
    const eventsEntries: CalendarEntryEvent<T>[] = dateEvents.map((evt) => {
      const processedDateEvent = processedEvents.find((e) => e.event === evt);

      const isFirstDayOfEvent = isSameDay(date, evt.startDate);
      const sunday = isSunday(date);
      const firstMonthDay = isFirstDayOfMonth(date);

      return {
        ...processedDateEvent!,
        showName: sunday || isFirstDayOfEvent || firstMonthDay,
        position: getEventPositionInDate(date, evt),
      };
    });
    return {
      date,
      events: eventsEntries,
    };
  });
};

export const useCalendarRenderEntries = <T>(
  date: Date,
  events: CalendarEvent<T>[]
) => {
  return React.useMemo(() => {
    const dates = getRenderDates(date);
    return buildRenderEntries<T>(dates, events);
  }, [date, events]);
};

export const CalendarUtils = {
  isCalendarEntry,
  isCalendarEntryEvent,
};
