import React from 'react';

interface TErrorBoundaryContext {
  error?: Error;
  errorInfo?: React.ErrorInfo;
  clearError: VoidFunction;
}

export const ErrorBoundaryContext = React.createContext<TErrorBoundaryContext>({
  clearError: () => {},
});

export const useError = (): TErrorBoundaryContext => {
  return React.useContext(ErrorBoundaryContext);
};

interface ErrorBoundaryProps {
  fallback: React.ReactNode;
  onError?(error: Error, errorInfo: React.ErrorInfo): void;
}
export class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>
> {
  state = { hasError: false, error: undefined, errorInfo: undefined };

  constructor(props: any) {
    super(props);

    this.clearError = this.clearError.bind(this);
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  clearError() {
    this.setState({
      error: undefined,
      hasError: false,
      errorInfo: undefined,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContext.Provider
          value={{ ...this.state, clearError: this.clearError }}
        >
          {this.props.fallback}
        </ErrorBoundaryContext.Provider>
      );
    }
    return this.props.children;
  }
}
