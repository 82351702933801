export enum ApiErrorType {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  Timeout = 408,
  InternalServerError = 500,
  NotImplemented = 501,
  Unknown,
}

export abstract class ApiError extends Error {
  constructor(public message: string, public type: ApiErrorType) {
    super(message);

    Object.setPrototypeOf(this, ApiError.prototype);
  }

  static isFailedRequest(response: Response): boolean {
    const code = response.status;

    const okCodes = [200, 201, 202, 203, 204, 304];

    return !okCodes.includes(code);
  }

  static fromResponse(response: Response): ApiError {
    switch (response.status) {
      case 400:
        return new BadRequestException();
      case 401:
        return new UnauthorizedException();
      case 403:
        return new ForbiddenException();
      case 404:
        return new NotFoundException();
      case 405:
        return new MethodNotAllowedException();
      case 408:
        return new TimeoutException();
      case 500:
        return new InternalServerErrorException();
      case 501:
        return new NotImplementedException();
      default:
        return new UnknownException();
    }
  }
}

export class BadRequestException extends ApiError {
  constructor() {
    super('Bad request', ApiErrorType.BadRequest);
  }
}

export class UnauthorizedException extends ApiError {
  constructor() {
    super('Unauthorized', ApiErrorType.Unauthorized);
  }
}

export class ForbiddenException extends ApiError {
  constructor() {
    super('Forbidden', ApiErrorType.Forbidden);
  }
}

export class NotFoundException extends ApiError {
  constructor() {
    super('Not found', ApiErrorType.NotFound);
  }
}

export class MethodNotAllowedException extends ApiError {
  constructor() {
    super('Method not allowed', ApiErrorType.MethodNotAllowed);
  }
}

export class TimeoutException extends ApiError {
  constructor() {
    super('Request timeout', ApiErrorType.Timeout);
  }
}

export class InternalServerErrorException extends ApiError {
  constructor() {
    super('Internal server error', ApiErrorType.InternalServerError);
  }
}

export class NotImplementedException extends ApiError {
  constructor() {
    super('Not implemented', ApiErrorType.NotImplemented);
  }
}

export class UnknownException extends ApiError {
  constructor() {
    super('Unknown error', ApiErrorType.Unknown);
  }
}
