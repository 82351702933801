import { Modal, Button, Group, ButtonWithIcon } from 'components';
import { useState, useEffect } from 'react';
import { ArrowBarLeft, ArrowBarRight, ArrowRight, X } from 'tabler-icons-react';

/**
 * Modal for alerting the user that is not using the production environment.
 * We are going to check for cds2-backoffice-test in the URL.
 * If the URL contains cds2-backoffice-test, we are going to show the modal and
 * redirect the user to the production environment.
 */
const PRODUCTION_URL = 'https://cds2-backoffice.copernicus-climate.eu';

export const UseProductionMessage = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('cds2-backoffice-test')) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    const restOfUrl = window.location.href.substring(window.location.origin.length)
    window.location.href = PRODUCTION_URL + restOfUrl;
  };

  const handleSilentClose = () => {
    setOpen(false);
  }

  if (!open) {
    return null;
  }
  return (
    <Modal
      onClose={handleClose}
      dismissable
      className='create-edit-catalog-entry'
      header={
        'You are not using the production environment.'
      }
      footer={
        <Group orientation='row' placement='end'>
          <ButtonWithIcon icon={<ArrowRight />} onClick={handleClose}>
            Got it, take me to the production environment
          </ButtonWithIcon>
        </Group>
      }
    >
      <Group orientation='column' placement='start'>
        <p>
          We are currently moving to the production environment.
          This environment will be used for <span onClick={handleSilentClose}>testing purposes</span> only.
        </p>
        <p>
          Please, update the URL to use the production environment:
          <div>
            <a href={PRODUCTION_URL}>
              {PRODUCTION_URL}
            </a>

          </div>
        </p>
      </Group>
    </Modal>
  );
};
