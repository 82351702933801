import useSWR from 'swr';

import { NewUserDTO, Page, PageQuery, Role, User } from 'domain/models';
import { PagedQueryParams } from 'domain/utils';
import { Api } from 'domain/core';
import { fetcher, rawFetcher } from './fetcher';

export const useUsers = (
  page: PageQuery = { page: 0, size: 10 },
  q: string = ''
): Page<User> => {
  const qs = new PagedQueryParams(page, q).getQueryParams();

  const { data } = useSWR<Page<User>>(`${Api.baseUrl}${Api.admin.users}?${qs}`);

  return data as Page<User>;
};

export const useUserFetcher = () => {
  return (page: PageQuery = { page: 0, size: 10 }, q: string = '') => {
    const qs = new PagedQueryParams(page, q).getQueryParams();

    return fetcher(
      `${Api.baseUrl}${Api.admin.users}?${qs}`
    ) as Promise<Page<User>>;
  }

};

export const useCreateUser = () => {
  return (user: NewUserDTO) => {
    return rawFetcher(
      `${Api.baseUrl}${Api.admin.users}`,
      {
        method: 'POST',
        body: JSON.stringify(user),
      }
    ).then((res) => {
      return res.json();
    });
  }

};

export const useUserRoles = () => {
  const { data } = useSWR(`${Api.baseUrl}${Api.admin.users}/roles`);
  return data as Page<Role> | undefined;
};


export const useUserByUsername = (username?: string): {
  user: User,
  update: (user: User) => Promise<any>,
} => {
  const baseUrl = `${Api.baseUrl}${Api.admin.users}`;
  let url: string | null = `${baseUrl}/${username}`;
  if (!username) {
    url = null;
  }

  const { data, mutate } = useSWR<User>(url) as {
    data: User,
    mutate: (user: User) => void,
  };

  const update = async (user: User) => {
    if (!user) {
      return;
    }
    try {
      await fetcher(baseUrl, {
        method: 'PUT',
        body: JSON.stringify(user),
      });
      mutate(user);
    } catch (error) {
      console.error(error);
    }
  };

  return { user: data, update, };
};
