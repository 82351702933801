import React from 'react';

import { addMonths, format, subMonths } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';

import { Group, Row } from 'components/Flex';
import { Text } from 'components/Text';

import './CalendarNavigation.scss';

interface CalendarNavigationProps {
  date: Date;
  onChange(newDate: Date): void;
}
export const CalendarNavigation = (props: CalendarNavigationProps) => {
  const onPrevClick = () => {
    props.onChange(subMonths(props.date, 1));
  };

  const onNextClick = () => {
    props.onChange(addMonths(props.date, 1));
  };

  return (
    <Group
      className='calendar-navigation'
      orientation='row'
      placement='space-between'
    >
      <Row onClick={onPrevClick} className='navigation-button prev-button'>
        <ChevronLeft color='var(--primary)' />
      </Row>
      <Text className='date-text'>{format(props.date, 'MMMM, yyyy')}</Text>
      <Row onClick={onNextClick} className='navigation-button next-button'>
        <ChevronRight color='var(--primary)' />
      </Row>
    </Group>
  );
};
