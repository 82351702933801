import React from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Responsive } from './ResponsiveContainer';
import { Row, Column } from './Flex';

import { Notification as INotification } from 'domain/models';

import './Notification.scss';
import { Circle, X } from 'tabler-icons-react';

export interface NotificationProps extends Omit<INotification, 'component'> {
  dismissMe(innerKey: number): void;
}

export const Notification = React.forwardRef<
  any,
  React.PropsWithChildren<NotificationProps>
>(
  (
    {
      className = '',
      message,
      variant = 'error',
      dismissible = true,
      innerKey,
      title,
      timeout = 5000,
      dismissMe,
      children,
    }: React.PropsWithChildren<NotificationProps>,
    ref: any
  ) => {
    React.useEffect(() => {
      setTimeout(() => {
        dismissMe(innerKey);
      }, timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const DismissButton = () => (
      <X
        className='dismiss-button'
        strokeWidth={1}
        size={18}
        onClick={() => {
          dismissMe(innerKey);
        }}
      />
    );

    const Content = (): any =>
      message ? (
        <span className='notification-content'>{message}</span>
      ) : (
        children
      );

    return (
      <Responsive>
        <Column className={`notification-wrapper ${className} ${variant}`}>
          <div className='border' />
          <Row className='progress-placeholder' />
          <Row
            className='progress'
            style={{ animationDuration: `${timeout}ms` }}
          />
          <Row className='notification-header'>
            <span className='notification-title'>{title}</span>
            {dismissible && <DismissButton />}
          </Row>
          <Row className={`notification`} ref={ref}>
            <Content />
          </Row>
        </Column>
      </Responsive>
    );
  }
);

export interface NotificationContainerProps {
  notifications: INotification[];
  dismissNotification(innerKey: number): void;
}

export const NotificationContainer = ({
  notifications,
  dismissNotification,
}: NotificationContainerProps) => {
  return (
    <Column className='notification-container'>
      <TransitionGroup className='notifications'>
        {notifications.map((not, index) => {
          return (
            <CSSTransition
              timeout={200}
              classNames='notification-transition'
              key={not.innerKey}
            >
              <Notification
                innerKey={not.innerKey}
                dismissMe={(innerKey: number) => dismissNotification(innerKey)}
                message={not.message}
                title={not.title}
                variant={not.variant}
                timeout={not.timeout}
              ></Notification>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </Column>
  );
};
