import React from 'react';

import Tippy from '@tippyjs/react';

import { useInputState } from 'domain/hooks';
import { Group } from '../Flex';

import { TokenItem } from './TokenBlock';
import { Suggestions } from './Suggestions';
import { BooleanOptionsComponents, OperatorOptionsComponents } from './Commons';

import {
  FixedSearchToken,
  SearchToken,
  SearchTokenOperator,
  SearchTokenOperatorList,
  SearchTokenOption,
  SearchTokenResult,
  SearchTokenValueType,
  TokenFilterStep,
} from './models';

import './TokenFilter.scss';

export const COMMON_TOKENS: SearchToken[] = [
  {
    code: 'name',
    label: 'Name',
    type: SearchTokenValueType.FreeText,
    operators: SearchTokenOperatorList,
  },
  {
    code: 'TIER',
    label: 'Tier',
    type: SearchTokenValueType.Select,
    operators: [SearchTokenOperator.Equals],
    options: [
      { label: 'Tier 1', value: 'TIER_1' },
      { label: 'Tier 2', value: 'TIER_2' },
      { label: 'Tier 3', value: 'TIER_3' }
    ],
  },
  {
    code: 'PRODUCT_TYPE',
    label: 'Product Type',
    type: SearchTokenValueType.Select,
    operators: SearchTokenOperatorList,
    options: [
      { label: 'Reanalysis', value: 'reanalysis' },
      { label: 'Satellite', value: 'satellite' },
      { label: 'Climate Projections', value: 'climate_projections' },
      { label: 'Seasonal', value: 'seasonal' },
    ],
  },
];

interface TokenFilterProps {
  tokens: SearchToken[];
  values: SearchTokenResult[];
  onValuesChange(newValues: SearchTokenResult[], submit?: boolean): void;
  /**
   * This function its only called when we are filling the value of a field.
   * Could be used to fill the options of a token asynchronously
   */
  onInputValueChange?(inputValue: string, activeToken?: SearchToken): void;

  onSubmit(values: SearchTokenResult[]): void;
}
export const TokenFilter = (props: TokenFilterProps) => {
  const [step, setStep] = React.useState<TokenFilterStep>(
    TokenFilterStep.SelectField
  );

  const suggestionsRef = React.useRef<any>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [showTippy, setShowTippy] = React.useState(false);

  const [inputVal, setInputVal] = useInputState('');
  const [activeIndex, setActiveIndex] = React.useState(0);

  const suggestions = useSuggestions(
    props.tokens,
    props.values,
    activeIndex,
    step
  );

  const activeToken = React.useMemo(() => {
    const activeStep = props.values.at(activeIndex);
    return props.tokens.find((t) => t.code === activeStep?.code);
  }, [props.values, activeIndex, props.tokens]);

  const onRemoveBlock = (index: number) => {
    props.onValuesChange(props.values.filter((v, i) => i !== index));
    setActiveIndex(props.values.length - 1);
    setStep(TokenFilterStep.SelectField);
    inputRef.current?.focus();
  };

  const onSuggestionClick = (option: SearchTokenOption) => {
    switch (step) {
      case TokenFilterStep.SelectField: {
        const token = props.tokens.find((t) => t.code === option.value);

        if (token?.type !== SearchTokenValueType.QuickFilter) {
          const newValue: SearchTokenResult = {
            code: option.value,
            token: token!,
          };

          props.onValuesChange(props.values.concat(newValue));
          setActiveIndex(props.values.length);
          setStep(TokenFilterStep.SelectOperator);
        } else if (token.type === SearchTokenValueType.QuickFilter) {
          const newValue: SearchTokenResult = {
            code: token.code,
            value: (token as FixedSearchToken).value,
            token,
          };

          props.onValuesChange(props.values.concat(newValue));
          setActiveIndex(props.values.length);
          setStep(TokenFilterStep.SelectField);
        }
        break;
      }
      case TokenFilterStep.SelectOperator: {
        const activeValue = props.values.at(activeIndex);
        props.onValuesChange(
          props.values.map((v) =>
            v.code === activeValue?.code ? { ...v, operator: option.value } : v
          )
        );
        setStep(TokenFilterStep.SelectValue);
        break;
      }
      case TokenFilterStep.SelectValue: {
        const activeValue = props.values.at(activeIndex);
        props.onValuesChange(
          props.values.map((v) =>
            v.code === activeValue?.code ? { ...v, value: option } : v
          )
        );
        setStep(TokenFilterStep.SelectField);
        break;
      }
    }
    setInputVal('');
    inputRef.current?.focus();
  };

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (step === TokenFilterStep.SelectValue && props.onInputValueChange) {
      if (activeToken) {
        props.onInputValueChange(evt.target.value, activeToken);
      }
    }
    setInputVal(evt.target.value);
  };

  const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.code === 'Tab' || evt.code === 'ArrowDown' || evt.code === 'Enter') {
      if (
        step === TokenFilterStep.SelectValue &&
        activeToken?.type === SearchTokenValueType.FreeText
      ) {
        if (inputVal.length !== 0) {
          onSuggestionClick({ value: inputVal.trim(), label: inputVal.trim() });
          evt.preventDefault();
          evt.stopPropagation();
        }
      } else {
        if (suggestions.length !== 0) {
          if (evt.code === 'Enter') {
            // Do search
            // Propagate event
            const hasText = inputVal.length !== 0;
            if (props.values.length === 0 && hasText) {
              const tokenized = buildFromFreeText(inputVal);
              props.onValuesChange(tokenized, true);
              props.onSubmit(tokenized);
              // Clear the input
              setInputVal('');
            }
          } else {
            suggestionsRef.current?.focus();
          }
        }
      }
    } else if (evt.code === 'Backspace' && inputVal.length === 0) {
      if (props.values.length !== 0) {
        removeLastToken();
      }
    }
  };

  /**
   * From free text to selected field.
   * What we do is the following:
   * Any text that starts with tier is considered the beginning of a tier token,
   * so we check the next word to see if it is a valid number. Available is:
   * - tier 1
   * - tier 2
   * - tier 3
   * 
   * Other text as treated as a free text search in the name field.
   */
  const buildFromFreeText = (inputVal: string): SearchTokenResult[] => {

    // Split the tokens
    const tokens = inputVal.split(' ');

    // Map with the tokens
    const tokenMap = {
      name: '',
      tier: '',
    };

    const tierBeginnings = ['tier'];
    // For each token
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      if (tierBeginnings.includes(token) && i + 1 < tokens.length) {
        const tier = tokens[i + 1];
        if (['1', '2', '3'].includes(tier)) {
          tokenMap.tier = "TIER_" + tier;
        }
        i++;
        continue;
      }
      tokenMap.name += token + ' ';
    }

    // Remove the last space
    tokenMap.name = tokenMap.name.trim();

    const result: SearchTokenResult[] = [];
    // Add the name
    if (tokenMap.name.length !== 0) {
      result.push({
        code: 'name',
        operator: SearchTokenOperator.Like,
        value: {
          label: tokenMap.name,
          value: tokenMap.name,
        },
        token: props.tokens.find((t) => t.code.toLowerCase() === 'name')!,
      });
    }
    // Add the name
    if (tokenMap.tier.length !== 0) {
      const token = props.tokens.find((t) => t.code.toLowerCase() === 'tier')!;
      result.push({
        code: token.code,
        operator: SearchTokenOperator.Equals,
        value: {
          label: tokenMap.tier.toLocaleLowerCase().replace('_', ' '),
          value: tokenMap.tier,
        },
        token: token,
      });
    }

    // Return the tokens
    return result;
  }


  const removeLastToken = () => {
    const lastValue = props.values.at(-1);

    if (lastValue) {
      let newValues = props.values;
      let newInputVal = '';
      let newStep = TokenFilterStep.SelectField;

      if (lastValue.value) {
        const valueLabel = lastValue.value.label;

        const newValue = {
          ...lastValue,
          value: undefined,
        };
        newInputVal = valueLabel;
        newStep = TokenFilterStep.SelectValue;
        newValues = props.values.map((v) =>
          v.code === lastValue.code ? newValue : v
        );
      } else if (lastValue.operator) {
        const newValue = {
          ...lastValue,
          operator: undefined,
          value: undefined,
        };
        newValues = props.values.map((v) =>
          v.code === lastValue.code ? newValue : v
        );
        newInputVal = '';
        newStep = TokenFilterStep.SelectOperator;
      } else if (lastValue.code) {
        newInputVal = lastValue.code;
        newStep = TokenFilterStep.SelectField;
        newValues = props.values.filter((v) => v.code !== lastValue.code);
      }

      props.onValuesChange(newValues);
      setInputVal(newInputVal);
      setStep(newStep);

      inputRef.current?.focus();
    }
  };

  return (
    <Group className='token-filter' placement='start' spacing='4px'>
      {props.values.map((tokenValue, idx) => {
        const token: any = props.tokens.find((t) => t.code === tokenValue.code);

        return token ? (
          <TokenItem
            key={token.code}
            token={token}
            tokenValue={tokenValue}
            index={idx}
            onRemoveBlock={() => onRemoveBlock(idx)}
          />
        ) : null;
      })}
      <Group className='token-filter-input-group' placement='start'>
        <Tippy
          interactive
          placement='bottom-start'
          animation='scale'
          visible={showTippy}
          onClickOutside={() => setShowTippy(false)}
          content={
            <Suggestions
              ref={suggestionsRef}
              tokens={props.tokens}
              options={suggestions}
              searchTerm={inputVal}
              onChange={onSuggestionClick}
            />
          }
        >
          <input
            value={inputVal}
            ref={inputRef}
            tabIndex={1}
            onKeyDown={onKeyDown}
            onChange={onInputChange}
            onClick={() => setShowTippy(true)}
          />
        </Tippy>
      </Group>
    </Group>
  );
};

const getFieldOptions = (
  tokens: SearchToken[],
  values: SearchTokenResult[]
) => {
  return tokens
    .filter((t) => {
      const val = values.find((v) => v.code === t.code);

      return !val;
    })
    .map((t) => ({ label: t.label, value: t.code }));
};

const getOperatorOptions = (
  tokens: SearchToken[],
  values: SearchTokenResult[],
  activeIndex: number
) => {
  const currentValue = values.at(activeIndex);

  if (currentValue) {
    const currentValueToken = tokens.find((t) => t.code === currentValue.code);
    if (
      currentValueToken &&
      currentValueToken.type !== SearchTokenValueType.QuickFilter
    ) {
      return currentValueToken.operators.map((o) => ({
        label: o,
        value: o,
        component: OperatorOptionsComponents[o],
      }));
    }
  }

  return [];
};

const getValueOptions = (
  tokens: SearchToken[],
  values: SearchTokenResult[],
  activeIndex: number
) => {
  const currentValue = values.at(activeIndex);

  if (currentValue) {
    const currentValueToken = tokens.find((t) => t.code === currentValue.code);
    if (
      currentValueToken &&
      currentValueToken.type !== SearchTokenValueType.FreeText
    ) {
      switch (currentValueToken.type) {
        case SearchTokenValueType.Boolean: {
          return (
            currentValueToken.options ?? [
              {
                value: true,
                label: 'Yes',
                component: BooleanOptionsComponents.true,
              },
              {
                value: false,
                label: 'No',
                component: BooleanOptionsComponents.false,
              },
            ]
          );
        }
        case SearchTokenValueType.Select: {
          return currentValueToken.options ?? [];
        }
      }
    }
  }

  return [];
};

const useSuggestions = (
  tokens: SearchToken[],
  values: SearchTokenResult[],
  activeIndex: number,
  step: TokenFilterStep
) => {
  return React.useMemo(() => {
    switch (step) {
      case TokenFilterStep.SelectField:
        return getFieldOptions(tokens, values);
      case TokenFilterStep.SelectOperator:
        return getOperatorOptions(tokens, values, activeIndex);
      case TokenFilterStep.SelectValue:
        return getValueOptions(tokens, values, activeIndex);
      default:
        return [];
    }
  }, [step, values, activeIndex, tokens]);
};
