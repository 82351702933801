import React from 'react';

import { Navigate } from 'react-router-dom';
import { useLoggedIn } from 'domain/swr';

export const ProtectedRoute = ({ children }: any) => {

  const loggedIn = useLoggedIn();

  return loggedIn === true ? (
    children
  ) : (
    <Navigate to={`/auth?continue=${window.location.pathname}`} />
  );
};
