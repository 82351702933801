import React from 'react';

import { ReactDatePickerProps } from 'react-datepicker';
import { Eye, EyeOff } from 'tabler-icons-react';
import { Props as ReactSelectProps } from 'react-select';

import { InputProps } from './Commons';
import { DateTimePicker } from './DateTimePicker';
import { Column, Group, Row } from './Flex';
import { Input } from './Input';
import { Select } from './Select';

import './FormControl.scss';
import Skeleton from 'react-loading-skeleton';
import { PeriodUtils } from 'domain/utils/period.utils';

interface FormControlProps {
  label: string | React.ReactNode;
  description?: string | React.ReactNode;
  error?: string | React.ReactNode;
  help?: string | React.ReactNode;
  id?: string;
  required?: boolean;
  className?: string;
}
export const FormControl = ({
  label,
  description,
  error,
  help,
  required = false,
  id,
  className = '',
  children,
}: React.PropsWithChildren<FormControlProps>) => {
  const Label = React.useCallback(
    ({ children: _children }: React.PropsWithChildren<{}>) => {
      return (
        <div
          className={`form-control-label text-base`}
          title={typeof label === 'string' ? label : ''}
        >
          <span className='form-control-label-text' id={id}>
            {label}
            {required && <sup className='required-symbol'>*</sup>}
          </span>
          {_children}
        </div>
      );
    },
    [required, label]
  );

  const Description = React.useCallback(() => {
    return description ? (
      <span>
        <span className='form-control-description'>{description}</span>
      </span>
    ) : null;
  }, [description]);

  const Error = React.useCallback(() => {
    return error ? <span className='form-control-error'>{error}</span> : null;
  }, [error]);

  const Help = React.useCallback(() => {
    return help ? <span className='form-control-help'>{help}</span> : null;
  }, [help]);

  return (
    <Column className={`form-control ${className}`} tabIndex={0}>
      <Label>
        <Description />
        {children}
        <Help />
        <Error />
      </Label>
    </Column>
  );
};

type InputControlProps = InputProps & Omit<FormControlProps, 'children'>;
export const InputControl = React.forwardRef(
  (props: InputControlProps, ref: any) => {
    const {
      description,
      label,
      error,
      help,
      className,
      required,
      ...inputProps
    } = props;

    return (
      <FormControl
        label={label}
        description={description}
        error={error}
        help={help}
        required={required}
        className={`input-control ${className ?? ''}`}
      >
        <Input
          {...inputProps}
          required={required}
          className={`input-control-input ${error ? 'with-error' : ''}`}
          ref={ref}
        />
      </FormControl>
    );
  }
);

type TypedControlProps = Omit<InputControlProps, 'type'>;
export const TextControl = React.forwardRef(
  (props: InputControlProps, ref: any) => {
    return <InputControl type='text' {...props} ref={ref} />;
  }
);

export const NumberControl = React.forwardRef(
  (props: TypedControlProps, ref: any) => {
    return <InputControl {...props} ref={ref} type='number' />;
  }
);

export const PasswordControl = React.forwardRef(
  (props: InputControlProps, ref: any) => {
    const [type, setType] = React.useState('password');

    const {
      description,
      label,
      error,
      help,
      className,
      type: _,
      ...inputProps
    } = props;

    return (
      <FormControl
        label={label}
        description={description}
        error={error}
        help={help}
        className={`input-control password-control ${className ?? ''}`}
      >
        <Row className='input-control-input-wrapper'>
          <Input
            className='input-control-input password-control-input'
            {...inputProps}
            ref={ref}
            type={type}
          />
          {type === 'text' ? (
            <EyeOff
              className='password-control-icon'
              onClick={() => setType('password')}
              size={20}
            />
          ) : (
            <Eye
              className='password-control-icon'
              onClick={() => setType('text')}
              size={20}
            />
          )}
        </Row>
      </FormControl>
    );
  }
);

type DateControlProps = FormControlProps & ReactDatePickerProps;
export const DateControl = React.forwardRef(
  (props: DateControlProps, ref: any) => {
    const { description, label, error, help, className, ...dateInputProps } =
      props;

    return (
      <FormControl
        label={label}
        description={description}
        error={error}
        help={help}
        className={`input-control date-control ${className ?? ''}`}
      >
        <DateTimePicker {...dateInputProps} ref={ref} />
      </FormControl>
    );
  }
);

type DurationControlProps = FormControlProps & {
  value: string;
  onChange: (value: string) => void;
};
export const DurationControl = React.forwardRef(
  (props: DurationControlProps, ref: any) => {
    const { description, label, error, help, className, ...inputProps } = props;
    // Multiple inputs fields for year, months, days, hours, minutes, seconds

    const duration = React.useMemo(() => {
      if (!props.value) {
        return PeriodUtils.empty();
      }
      const parsed = PeriodUtils.fromString(inputProps.value as string);
      return parsed || PeriodUtils.empty();
    }, [inputProps.value]);

    const change = (field: string, strValue: string) => {
      let value = 0;
      if (strValue && !isNaN(Number(strValue))) {
        value = parseInt(strValue);
      }
      const newDuration = { ...duration, [field]: value };
      const str = PeriodUtils.toString(newDuration);
      inputProps.onChange?.(str);
    };

    const fields = [
      {
        label: 'Years',
        code: 'years',
      },
      {
        label: 'Months',
        code: 'months',
      },
      {
        label: 'Days',
        code: 'days',
      },
      {
        label: 'Hours',
        code: 'hours',
      },
      {
        label: 'Minutes',
        code: 'minutes',
      },
      {
        label: 'Seconds',
        code: 'seconds',
      },
    ];
    return (
      <FormControl
        label={label}
        description={description}
        error={error}
        help={help}
        className={`input-control duration-control ${className ?? ''}`}
      >
        <Row className='duration-control-wrapper'>
          {fields.map((field) => {
            return (
              <Column
                className='duration-control-field'
                key={`duration-control-field-${field.code}`}
              >
                <span className='duration-control-field__label'>
                  {field.label}
                </span>
                <Input
                  placeholder='0'
                  // @ts-ignore
                  value={duration[field.code]}
                  type='number'
                  onChange={({ target }) => change(field.code, target.value)}
                />
              </Column>
            );
          })}
        </Row>
      </FormControl>
    );
  }
);

type SelectControlProps = FormControlProps & ReactSelectProps;
export const SelectControl = React.forwardRef(
  (props: SelectControlProps, ref: any) => {
    const { description, label, error, help, className, ...selectProps } =
      props;

    return (
      <FormControl
        label={label}
        description={description}
        error={error}
        help={help}
        className={`input-control select-control ${className ?? ''}`}
      >
        <Select {...selectProps} ref={ref} />
      </FormControl>
    );
  }
);

export const FormControlSkeleton = () => {
  return (
    <Group
      className='form-control skeleton'
      orientation='column'
      placement='start'
    >
      <Skeleton width={250} height={20} />
      <Skeleton width={400} height={15} />
      <Skeleton
        width={'100%'}
        height={30}
        containerClassName='input-skeleton'
      />
    </Group>
  );
};
