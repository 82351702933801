import React from 'react';

import useIntersectionObserver, {
  MockIntersectionObserverEntry,
} from '@react-hook/intersection-observer';
import { mergeRefs } from 'domain/utils';

//import { useWhyDidYouUpdate } from 'domain/hooks/useWhyDidYouUpdate';

export interface OnAppearProps {
  root?: any;
  rootMargin?: string;
  threshold?: number;
  cssClass?: string;
  baseCssClass?: string;
  onlyOnAppear?: boolean;
  onAppear?(
    entry: IntersectionObserverEntry | MockIntersectionObserverEntry
  ): void;
  onDisappear?(
    entry: IntersectionObserverEntry | MockIntersectionObserverEntry
  ): void;
}

export const OnAppear = ({
  cssClass = '',
  baseCssClass = 'observed',
  root = null,
  rootMargin = '0%',
  threshold = 0,
  onlyOnAppear = true,
  onAppear,
  onDisappear,
  children,
}: React.PropsWithChildren<OnAppearProps>) => {
  const elRef = React.useRef<HTMLElement>(null);

  const intersectionEntry = useIntersectionObserver(elRef, {
    root,
    rootMargin,
    threshold,
    initialIsIntersecting: false,
  });

  const [appliedClass, applyClass] = React.useState<string>('');

  const firstRender = React.useRef(true);

  React.useEffect(() => {
    if (intersectionEntry.target && firstRender.current) {
      firstRender.current = false;
    }
    if (intersectionEntry.isIntersecting) {
      applyClass(cssClass);
      if (!firstRender.current && onAppear) {
        onAppear(intersectionEntry);
      }
    } else {
      if (!onlyOnAppear) {
        applyClass('');
      }
      if (!firstRender.current && onDisappear) {
        onDisappear(intersectionEntry);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersectionEntry]);

  return React.Children.map(children, (child: any) =>
    React.cloneElement(child, {
      className: `${
        child.props.className || ''
      } ${baseCssClass} ${appliedClass}`,
      ref: mergeRefs(child.ref, elRef),
    })
  ) as any;
};

// export const OnAppear = React.memo((props: any) => {
//   useWhyDidYouUpdate('OnAppear', props);
//   return <_OnAppear {...props} />;
// });
