import { Api } from 'domain/core';
import { Page, PageQuery, UserRequest } from 'domain/models';
import { PagedQueryParams } from 'domain/utils';
import useSWR from 'swr';

export const useUserRequests = (page: PageQuery = { page: 0, size: 10 }) => {
  const qs = new PagedQueryParams(page);

  const url = `${Api.baseUrl}${Api.admin.userRequests}?${qs.getQueryParams()}`

  const { data } = useSWR<Page<UserRequest[]>>(url);

  return data as Page<UserRequest[]>;
};
