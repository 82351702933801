import React from 'react';

import { DivProps } from './Commons';

import './FullPage.scss';

export const FullPage = React.memo(
  React.forwardRef<any, React.PropsWithChildren<DivProps>>(
    (
      {
        children,
        className,
        ...nativeProps
      }: React.PropsWithChildren<DivProps>,
      ref: any
    ) => (
      <div
        ref={ref}
        {...nativeProps}
        className={`full-page ${className || ''}`}
      >
        {children}
      </div>
    )
  )
);

export type BackdropProps = DivProps & {
  dismissable: boolean;
  onDismiss?: VoidFunction;
};

export const FullPageBackdrop = React.memo(
  React.forwardRef<any, React.PropsWithChildren<BackdropProps>>(
    (
      {
        children,
        onClick,
        onDismiss,
        dismissable,
        className,
        ...nativeProps
      }: React.PropsWithChildren<BackdropProps>,
      ref: any
    ) => {
      const dismissOnClick = (
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
      ) => {
        if (dismissable) {
          onDismiss && onDismiss();
        } else {
          onClick && onClick(evt);
        }
      };

      return (
        <FullPage
          ref={ref}
          onClick={dismissOnClick}
          className={`full-page-backdrop ${className || ''}`}
          {...nativeProps}
        >
          {children}
        </FullPage>
      );
    }
  )
);
