import { isValid } from 'domain/utils';
import { AuthorizationService } from 'domain/services';

import { Token } from 'domain/models';

export abstract class AuthorizedService {
  protected async fetch(
    input: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response> {
    const validToken = await this.getValidToken();
    return this.delegate(input, init, validToken);
    // If fetch returns 403/401 try to refresh, if fails redirect to /auth
  }

  protected async fetchRaw(
    input: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response> {
    const validToken = await this.getValidToken();
    return this.delegateRaw(input, init, validToken);
    // If fetch returns 403/401 try to refresh, if fails redirect to /auth
  }

  private async getValidToken(): Promise<Token> {
    const token = AuthorizationService.getAuthorization();
    const [valid, remaining] = isValid(token);
    if (valid && remaining > 1000 * 120) {
      // 2 Minutes
      return token as Token;
    } else {
      const newToken = await new AuthorizationService().renew();
      return newToken;
    }
  }

  private delegate(
    input: RequestInfo,
    init: RequestInit | undefined = { headers: {} },
    token: Token
  ): Promise<Response> {

    // If init.headers.Content-Type is undefined, set it to application/json
    // If is null, do nothing (i.e. multipart/form-data)
    const contentHeader: any = {};
    if (init.headers && (init.headers as any)['Content-Type'] === null) {
      // do nothing
      // Delete from init.headers, so it doesn't override the contentHeader
      delete (init.headers as any)['Content-Type'];
    } else {
      // set to application/json
      contentHeader['Content-Type'] = 'application/json';
    }


    return fetch(input, {
      ...init,
      headers: {
        ...contentHeader,
        ...init.headers,
        ...this.buildAuthorizationHeader(token),
      },
    });
  }

  private delegateRaw(
    input: RequestInfo,
    init: RequestInit | undefined = { headers: {} },
    token: Token
  ): Promise<Response> {
    return fetch(input, {
      ...init,
      headers: {
        ...init.headers,
        ...this.buildAuthorizationHeader(token),
      },
    });
  }

  private buildAuthorizationHeader(token: Token): { Authorization: string } {
    return { Authorization: `Bearer ${token.access_token}` };
  }
}

export class AuthorizatedServiceImpl extends AuthorizedService {
  public async fetch(
    input: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response> {
    return this.fetchRaw(input, init);
  }
}

export const authorizedService = new AuthorizatedServiceImpl();
