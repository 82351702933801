import React from 'react';

import './MaxLengthIndicator.scss';

interface MaxLengthIndicatorProps {
  className?: string;
  maxLength?: number;
  currentLength: number;
}
export const MaxLengthIndicator = (props: MaxLengthIndicatorProps) => {
  return props.maxLength !== undefined ? (
    <span
      className={`max-length-indicator ${props.className ?? ''} ${
        props.currentLength >= props.maxLength ? 'limit-reached' : ''
      }`}
    >
      {props.currentLength}/{props.maxLength}
    </span>
  ) : null;
};
