import React from 'react';

import { Group, Row } from 'components/Flex';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';

import { SearchToken, SearchTokenOption, SearchTokenValueType } from './models';

import './Suggestions.scss';
import { Bolt } from 'tabler-icons-react';

interface SuggestionsProps {
  options: SearchTokenOption[];
  selectedOption?: any;
  searchTerm: string;
  tokens: SearchToken[];
  onChange(option: SearchTokenOption): void;
}
export const Suggestions = React.forwardRef(
  (props: SuggestionsProps, ref: any) => {
    const listRef = React.useRef<HTMLDivElement>(null);

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        listRef.current?.focus();
      },
    }));

    const handleOptionKeyDown =
      (option: SearchTokenOption) =>
      (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.code === 'Space' || evt.code === 'Enter') {
          props.onChange(option);
          evt.preventDefault();
        }
      };

    return (
      <Group
        className='suggestions-content'
        placement='start'
        spacing='0px'
        orientation='column'
        tabIndex={0}
        ref={listRef}
      >
        {props.options
          .filter((opt) => {
            if (props.searchTerm) {
              return opt.label
                .toLowerCase()
                .includes(props.searchTerm.toLowerCase());
            }
            return true;
          })
          .map((option, idx) => {
            const token = props.tokens.find((t) => t.code === option.value);
            const quickFilter =
              token?.type === SearchTokenValueType.QuickFilter;
            return (
              <Group
                className={`suggestion-content-option ${
                  option.value === props.selectedOption?.value ? 'selected' : ''
                }`}
                key={option.value}
                tabIndex={0}
                placement='start'
                onClick={() => props.onChange(option)}
                onKeyDown={handleOptionKeyDown(option)}
              >
                {option.component ? (
                  option.component
                ) : (
                  <Row className='suggestion-content-option-default'>
                    <Text className='suggestion-content-option-text'>
                      {option.label}{' '}
                    </Text>
                    {quickFilter && (
                      <Tooltip content={'Quick filter'}>
                        <Row className='fixed-suggestion-icon-wrapper'>
                          <Bolt
                            xlinkTitle='Quick filter'
                            className='fixed-suggestion-icon'
                            strokeWidth={1}
                            size={16}
                          />
                        </Row>
                      </Tooltip>
                    )}
                  </Row>
                )}
              </Group>
            );
          })}
      </Group>
    );
  }
);
