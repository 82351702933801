import React from 'react';

import useSWR from 'swr';

import { Api } from 'domain/core';
import { Page, PageQuery, Task, TaskSchema } from 'domain/models';
import { PagedQueryParams } from 'domain/utils';
import { fetcher, rawFetcher } from './fetcher';

export const useTasks = (
  page: PageQuery = { page: 0, size: 10 },
  q?: string,
  filters?: string[]
): Page<Task> => {
  const qs = new PagedQueryParams(page, q);
  if (filters) {
    filters.forEach((f) => qs.addParam('filters', f));
  }
  const { data } = useSWR<Page<Task>>(
    `${Api.baseUrl}${Api.tasks.findAll}?${qs.getQueryParams()}`
  );
  return data as Page<Task>;
};

export const useTask = (taskId: string) => {
  const url = taskId ? `${Api.baseUrl}${Api.tasks.findAll}/${taskId}` : null;
  const { data: task, mutate } = useSWR<Task>(url);

  return { task, mutate } as { task: Task; mutate: () => Promise<Task> };
};

export const useUpdateTaskDueDate = () => {
  const updateTaskDueDate = React.useCallback(
    (task: Partial<Task>) =>
      rawFetcher(`${Api.baseUrl}${Api.tasks.findAll}/${task.id}/dueDate`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ due_date: task.due_date }),
      }),
    []
  );

  return { updateTaskDueDate };
};

export const useTasksByExecution = (
  executionId: string,
  pageQuery: PageQuery = { page: 0, size: 10 }
): Page<Task> => {
  const qs = new PagedQueryParams(pageQuery).getQueryParams();
  let url:
    | string
    | null = `${Api.baseUrl}${Api.executions.findAll}/${executionId}/tasks?${qs}`;
  if (!executionId) {
    url = null;
  }

  const { data } = useSWR<Page<Task>>(url);

  return data as Page<Task>;
};

export const useUpdateTask = () =>
  React.useCallback(
    (id: string, draft: boolean, taskVariables: Partial<Task['variables']>) => {
      return rawFetcher(
        `${Api.baseUrl}${Api.tasks.findAll}/${id}${
          draft ? '?draft=true' : '?draft=false'
        }`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(taskVariables),
        }
      );
    },
    []
  );

export const useDeleteTask = () =>
  React.useCallback(
    (task: Partial<Task>) =>
      rawFetcher(`${Api.baseUrl}${Api.tasks.findAll}/${task.id}`, {
        method: 'DELETE',
      }),
    []
  );

export const useTaskSchema = (id: string): TaskSchema => {
  let url: string | null = `${Api.baseUrl}${Api.tasks.jsonSchema(id)}`;
  if (!id) {
    url = null;
  }
  // url = '/mock/schemas/tier-2-schema.json';

  const { data } = useSWR<TaskSchema>(url);

  return data as TaskSchema;
};

/**
 * This method makes the task assigned to the user,
 * or if the user is already assigned, it will do nothing.
 * @param id
 */
export const useTaskAssigned = (id: string): any => {
  const { data, mutate } = useSWR<any>(
    `${Api.baseUrl}${Api.tasks.findAll}/${id}/assigned`
  );

  const assign = () => {
    fetcher(`${Api.baseUrl}${Api.tasks.findAll}/${id}/assigned`, {
      method: 'PUT',
    }).then(() => mutate());
  };

  const unassign = () => {
    rawFetcher(`${Api.baseUrl}${Api.tasks.findAll}/${id}/assigned`, {
      method: 'DELETE',
    }).then(() => {
      mutate();
    });
  };

  return {
    assigned: data?.assigned,
    editable: data?.editable,
    assign,
    unassign,
  };
};

export const useTaskData = (id: string): any => {
  let url: string | null = `${Api.baseUrl}${Api.tasks.data(id)}`;
  if (!id) {
    url = null;
  }

  const { data } = useSWR<any>(url);

  return data as any;
};

export const useHistoryTasks = (processId?: string): Page<Task> => {
  let url: string | null = `${Api.baseUrl}${Api.tasks.history(
    processId || ''
  )}`;
  if (!processId) {
    url = null;
  }

  const { data } = useSWR<Page<Task>>(url);

  return data as Page<Task>;
};
