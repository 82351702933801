import React from 'react';

import { Api } from 'domain/core';
import { CatalogueEntry, Page, PageQuery } from 'domain/models';
import { PagedQueryParams, SelectOption } from 'domain/utils';
import { fetcher, rawFetcher } from './fetcher';
import useSWR from 'swr';
import { DashboardContent } from 'domain/models/dashboard.model';

export const useCatalogueEntryFetcher = () => {
  return React.useCallback(
    (searchTerm: string): Promise<SelectOption<string>[]> =>
      fetcher(
        `${Api.baseUrl}${Api.catalogEntries.findAll}?q=${searchTerm}`
      ).then((ce: Page<CatalogueEntry>) =>
        ce.content.map((c) => ({
          label: c.title,
          value: c.id,
        }))
      ),
    []
  );
};

export const useCatalogueEntries = (
  page: PageQuery = { page: 0, size: 10 },
  q: string = '',
  filters?: string[]
) => {
  const qs = new PagedQueryParams(page, q);

  if (filters) {
    filters.forEach((f) => qs.addParam('filters', f));
  }

  const { data, mutate } = useSWR<Page<CatalogueEntry>>(
    `${Api.baseUrl}${Api.catalogEntries.findAll}?${qs.getQueryParams()}`
  );

  return { data: data as Page<CatalogueEntry>, mutate };
};

export const useUpdateCatalogEntry = () =>
  React.useCallback((entry: CatalogueEntry) => {
    return rawFetcher(`${Api.baseUrl}${Api.catalogEntries.findAll}`, {
      method: 'PUT',
      body: JSON.stringify(entry),
    });
  }, []);

export const useCreateCatalogEntry = () =>
  React.useCallback((entry: CatalogueEntry) => {
    return rawFetcher(`${Api.baseUrl}${Api.catalogEntries.findAll}`, {
      method: 'POST',
      body: JSON.stringify(entry),
    });
  }, []);

export const useCatalogueEntriesByFamily = (
  family: string,
  page: PageQuery = { page: 0, size: 10 }
): Page<CatalogueEntry> => {
  const qs = new PagedQueryParams(page).getQueryParams();

  let url:
    | string
    | null = `${Api.baseUrl}${Api.catalogEntries.findAll}?${qs}&filters=[EQ][family]${family}`;

  if (family === 'undefined' || family === 'null') {
    url = null;
  }

  const { data } = useSWR<Page<CatalogueEntry>>(url);

  return data as Page<CatalogueEntry>;
};

export const useCatalogueEntry = (id: string) => {
  const { data } = useSWR<CatalogueEntry>(
    `${Api.baseUrl}${Api.catalogEntries.findAll}/${id}`
  );

  return data as CatalogueEntry;
};

export const useCatalogueEntriesByIds = (ids: string[]) => {
  const { data } = useSWR<{ content: CatalogueEntry[] }>(
    `${Api.baseUrl}${Api.catalogEntries.findAll}?${ids
      ?.map((id) => `filters=[EQ][id]${id}`)
      .join('&')}`
  );

  return (data?.content || []) as CatalogueEntry[];
};

export const useCatalogueEntriesStatus = () => {
  const { data } = useSWR<{ content: DashboardContent[] }>(
    `${Api.baseUrl}${Api.eqcDashboard.listAll}`
  );

  return (data?.content || []) as DashboardContent[];
};
