import React from 'react';

import Tippy from '@tippyjs/react';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';

import { Group, Row } from '../Flex';
import { Ripple } from '../Ripple';
import { ListWrapperContext } from './ListComponents';
import { Text } from '../Text';

import { range } from 'domain/utils';
import { Page } from 'domain/models';

import './Pagination.scss';
import { ListConfigurationButton } from '.';

const toRenderPages = (
  page: number,
  pages: number
): { toRender: number[]; prevEllipsis: boolean; nextEllipsis: boolean } => {
  let toRender, prevEllipsis, nextEllipsis;
  if (pages > 3) {
    if (page === 1) {
      toRender = range(3, 1);
      prevEllipsis = false;
      if (page + 3 < pages) {
        nextEllipsis = true;
      } else {
        nextEllipsis = false;
      }
    } else if (page === pages) {
      toRender = [pages - 2, pages - 1, pages];
      nextEllipsis = false;
      if (pages - 3 > 1) {
        prevEllipsis = true;
      } else {
        prevEllipsis = false;
      }
    } else {
      toRender = [page - 1, page, page + 1];
      prevEllipsis = true;
      nextEllipsis = true;
      if (page - 2 <= 1) {
        prevEllipsis = false;
      }

      if (page + 2 >= pages) {
        nextEllipsis = false;
      }
    }
  } else {
    toRender = range(pages, 1);
    prevEllipsis = false;
    nextEllipsis = false;
  }

  toRender = toRender.filter((n) => n !== 1 && n !== pages);
  return { toRender, prevEllipsis: prevEllipsis, nextEllipsis: nextEllipsis };
};

export type PaginationProps = Partial<Pick<Page<{}>, 'totalPages'>> &
  Pick<Page<{}>, 'page'> & {
    size?: number;
    totalElements?: number;
    empty?: boolean;
    onPageChange(page: number): void;
    onPageSizeChange?(pageSize: number): void;
  };

export const Pagination = ({
  page,
  empty,
  size,
  totalElements,
  totalPages,
  onPageChange,
  onPageSizeChange,
}: PaginationProps) => {
  const { dataPage, id } = React.useContext(ListWrapperContext);

  const finalProps = React.useMemo(() => {
    let _totalPages = totalPages ?? dataPage?.totalPages ?? 0;
    let _size = size ?? dataPage?.size ?? 10;
    let _empty = empty ?? dataPage?.empty ?? false;
    let _totalElements = totalElements ?? dataPage?.totalElements ?? 0;
    let _numberOfElements = dataPage?.numberOfElements ?? 0;
    return {
      totalPages: _totalPages,
      totalElements: _totalElements,
      size: _size,
      empty: _empty,
      numberOfElements: _numberOfElements,
    };
  }, [totalPages, size, totalElements, empty, dataPage]);

  const number = page + 1;
  const { toRender, prevEllipsis, nextEllipsis } = toRenderPages(
    number,
    finalProps.totalPages
  );

  const intOnPageChange = React.useCallback(
    (evtPage: 'prev' | 'next' | number) => {
      if (evtPage === number) {
        return;
      }

      if (evtPage === 'prev') {
        if (page > 0) {
          onPageChange(page - 1);
        }
      } else if (evtPage === 'next') {
        if (page < finalProps.totalPages - 1) {
          onPageChange(page + 1);
        }
      } else {
        onPageChange(evtPage - 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [number, finalProps, onPageChange]
  );

  const showPageSize =
    finalProps.size && finalProps.totalElements && onPageSizeChange;

  return (
    <Group placement='space-between' className={`pagination-container`}>
      {!finalProps.empty ? (
        <Row className='pagination'>
          <PrevButton onClick={() => intOnPageChange('prev')} />
          <PaginationButton
            active={number === 1}
            onClick={() => intOnPageChange(1)}
          >
            1
          </PaginationButton>
          {prevEllipsis && (
            <PaginationButton className='ellipsis' onClick={() => {}}>
              ...
            </PaginationButton>
          )}
          {toRender.map((n) => (
            <PaginationButton
              key={n}
              onClick={() => intOnPageChange(n)}
              active={number === n}
            >
              {n}
            </PaginationButton>
          ))}
          {nextEllipsis && (
            <PaginationButton className='ellipsis' onClick={() => {}}>
              ...
            </PaginationButton>
          )}
          {finalProps.totalPages > 1 && (
            <PaginationButton
              active={number === totalPages}
              onClick={() => intOnPageChange(finalProps.totalPages)}
            >
              {totalPages}
            </PaginationButton>
          )}
          <NextButton onClick={() => intOnPageChange('next')} />
          {showPageSize && (
            <PaginationInfo
              onPageSizeChange={onPageSizeChange!}
              numberOfElements={finalProps.numberOfElements}
              totalElements={finalProps.totalElements}
            />
          )}
        </Row>
      ) : (
        <Row />
      )}
      <Group placement='end' className='list-settings-container'>
        <Row
          className='list-download-options'
          id={`list-download-options_${id}`}
        />
        <Row
          className='list-configuration-wrapper'
          id={`list-configuration-wrapper_${id}`}
        />
      </Group>
    </Group>
  );
};

type PaginationButtonProps = React.PropsWithChildren<{
  className?: string;
  active?: boolean;
  onClick(evt: any): void;
}>;
const PaginationButton = ({
  className = '',
  active,
  onClick,
  children,
}: PaginationButtonProps) => (
  <Ripple>
    <Row
      className={`pagination-button ${active ? 'active' : ''} ${className}`}
      onClick={onClick}
    >
      {children}
    </Row>
  </Ripple>
);

const PrevButton = (props: PaginationButtonProps) => (
  <PaginationButton {...props}>
    <ChevronLeft />
  </PaginationButton>
);

const NextButton = (props: PaginationButtonProps) => (
  <PaginationButton {...props}>
    <ChevronRight />
  </PaginationButton>
);

const PageSizeOptions = [10, 25, 50];
interface PaginationInfoProps {
  totalElements: number;
  numberOfElements: number;
  onPageSizeChange(pageSize: number): void;
}
const PaginationInfo = (props: PaginationInfoProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <Row className='pagination-info-row'>
      Showing{' '}
      <Tippy
        interactive
        animation='scale'
        visible={showTooltip}
        onClickOutside={() => setShowTooltip(false)}
        content={
          <PageSizeTooltipContent
            options={PageSizeOptions}
            selectedOption={props.numberOfElements}
            onChange={(opt) => {
              props.onPageSizeChange(opt);
              setShowTooltip(false);
            }}
          />
        }
      >
        <Text
          className='selected-page-size'
          onClick={() => setShowTooltip(true)}
        >
          {props.numberOfElements}
        </Text>
      </Tippy>
      elements of {props.totalElements}
    </Row>
  );
};

const PageSizeTooltipContent = ({
  options,
  selectedOption,
  onChange,
}: {
  options: number[];
  selectedOption: number;
  onChange(option: number): void;
}) => {
  return (
    <Group
      orientation='column'
      placement='start'
      className='page-size-options-container'
    >
      {options.map((opt) => (
        <Text
          key={opt}
          onClick={() => onChange(opt)}
          className={`page-size-option ${
            opt === selectedOption ? 'selected' : ''
          }`}
        >
          {opt}
        </Text>
      ))}
    </Group>
  );
};
