import { Column, Group } from 'components/Flex';
import { Text } from 'components/Text';

import { format } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceFloppy, X } from 'tabler-icons-react';
import { useFormData } from '../../domain/hooks';
import { AppStore } from '../../domain/models';
import { popModal, pushModal } from '../../domain/store/root/root.actions';
import { Button, ButtonWithIcon } from '../Button';
import { DateControl, TextControl } from '../FormControl';
import { Modal } from '../Modal';

import './CalendarTooltips.scss';

import {
  CalendarEntry,
  CalendarEntryEvent,
  CalendarEvent,
  CalendarEventMs,
} from './utils';

interface CalendarDayTooltipProps {
  date: Date;
  dateEntry: CalendarEntry<any>;
  allowCreation: boolean;
  onClose: VoidFunction;
  setEvents: (event: CalendarEventMs) => void;
  events: CalendarEvent<any>[];
}
export const CalendarDayTooltip = (props: CalendarDayTooltipProps) => {
  const { username } = useSelector((store: AppStore) => store.auth.user!.user);
  const dispatch = useDispatch();

  const AddEventModal = () => {
    const [event, setEventFormData] = useFormData<CalendarEventMs>({
      name: `Event ${props.events.length + 1}`,
      startDate: props.date.getTime(),
      endDate: props.date.getTime(),
      user: username,
    });

    const [datepickerSelected, setDatePickerSelected] =
      useState<boolean>(false);

    const createEvent = () => {
      props.setEvents(event);
      dispatch(popModal());
    };

    return (
      <Modal
        className={`add-event-modal ${
          datepickerSelected ? 'datepicker-selected' : ''
        }`}
        header={'Create event'}
        onClose={() => dispatch(popModal())}
        dismissable
      >
        <Group>
          <TextControl
            label={'Name:'}
            value={event.name}
            onChange={setEventFormData('name')}
          />
        </Group>
        <Group>
          <DateControl
            label={'Start date:'}
            value={new Date(event.startDate).toLocaleDateString()}
            onInputClick={() => setDatePickerSelected(true)}
            onBlur={() => setDatePickerSelected(false)}
            onChange={(e) => {
              setEventFormData('startDate')(e?.getTime());
            }}
          />
        </Group>
        <Group>
          <DateControl
            label={'End date:'}
            value={new Date(event.endDate).toLocaleDateString()}
            onInputClick={() => setDatePickerSelected(true)}
            onBlur={() => setDatePickerSelected(false)}
            minDate={new Date(event.startDate)}
            onChange={(e) => setEventFormData('endDate')(e?.getTime())}
          />
        </Group>
        <Group placement='end'>
          <ButtonWithIcon
            icon={<DeviceFloppy />}
            className={'create-event-button'}
            onClick={createEvent}
          >
            Save event
          </ButtonWithIcon>
        </Group>
      </Modal>
    );
  };

  const pushAddEvent = () => {
    props.onClose();
    dispatch(pushModal(<AddEventModal />));
  };

  return (
    <TooltipPanel onClose={props.onClose}>
      <Group
        className='calendar-tooltip day-tooltip'
        orientation='column'
        placement='start'
      >
        <Text className='tooltip-day'>
          {format(props.date, 'd MMMM, yyyy')}
        </Text>
        <Group
          className='tooltip-events'
          orientation='column'
          placement='start'
          spacing='4px'
        >
          {props.dateEntry.events.map((evt) => (
            <Group
              key={evt.key}
              className='tooltip-event'
              orientation='column'
              placement='start'
              spacing='0.25em'
              style={{ backgroundColor: evt.color }}
            >
              <Text className='event-name'>{evt.event.name}</Text>
              <Text className='event-dates'>
                From {format(evt.event.startDate, 'dd-MM-yyyy HH:mm')} to{' '}
                {format(evt.event.endDate, 'dd-MM-yyyy HH:mm')}
              </Text>
            </Group>
          ))}
        </Group>
        {props.allowCreation && (
          <Group placement='end'>
            <Button onClick={pushAddEvent}>+ Add event</Button>
          </Group>
        )}
      </Group>
    </TooltipPanel>
  );
};

interface CalendarEventTooltipProps {
  date: Date;
  event: CalendarEntryEvent<any>;
  onClose: VoidFunction;
}
export const CalendarEventTooltip = (props: CalendarEventTooltipProps) => {
  return (
    <TooltipPanel onClose={props.onClose}>
      <Group
        className='calendar-tooltip event-tooltip'
        orientation='column'
        placement='start'
      >
        <Group
          className='tooltip-event'
          orientation='column'
          placement='start'
          spacing='0.5em'
          style={{ backgroundColor: props.event.color }}
        >
          <Text className='event-name'>{props.event.event.name}</Text>
          <Text className='event-dates'>
            From {format(props.event.event.startDate, 'dd-MM-yyyy HH:mm')} to{' '}
            {format(props.event.event.endDate, 'dd-MM-yyyy HH:mm')}
          </Text>
        </Group>
      </Group>
    </TooltipPanel>
  );
};

const TooltipPanel = ({
  onClose,
  children,
}: Pick<CalendarDayTooltipProps, 'onClose'> & React.PropsWithChildren) => {
  return (
    <Column className='tooltip-wrapper'>
      <X className='tooltip-close-icon' onClick={onClose} strokeWidth={1} />
      {children}
    </Column>
  );
};
