import { Tooltip } from 'components';
import { ActionIcon } from 'components/Button';
import { SecurePortal } from 'components/SecurePortal';
import React from 'react';
import { FileSpreadsheet } from 'tabler-icons-react';
import { ListWrapperContext } from './ListComponents';

interface ListDownloadOptionsProps {}
const Container = ({
  children,
}: React.PropsWithChildren<ListDownloadOptionsProps>) => {
  const { id } = React.useContext(ListWrapperContext);

  return (
    <SecurePortal id={`list-download-options_${id}`}>{children}</SecurePortal>
  );
};

interface ListDownloadOptionProps {
  className?: string;
  onClick: VoidFunction;
}
const ExcelDownloadOption = ({
  className = '',
  onClick,
}: ListDownloadOptionProps) => {
  return (
    <Tooltip content='Download as Excel'>
      <ActionIcon
        className={`list-download-option ${className}`}
        onClick={onClick}
      >
        <FileSpreadsheet strokeWidth={2} />
      </ActionIcon>
    </Tooltip>
  );
};

export const ListDownloadOptions = {
  Container,
  Excel: ExcelDownloadOption,
};
