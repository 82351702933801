import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'react-loading-skeleton/dist/skeleton.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { i18next } from 'domain/core';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
