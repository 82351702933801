import React from 'react';

import {
  Check,
  Equal,
  Asterisk,
  EqualNot,
  IconProps,
  Search,
  X,
} from 'tabler-icons-react';

import { Group, Row } from 'components/Flex';
import { SwitchCase, SwitchDefault, Switch } from 'components/logical';
import { Text } from 'components/Text';
import { Ripple } from 'components/Ripple';
import { Tooltip } from 'components/Tooltip';

import { SearchTokenOperator } from './models';

import './Commons.scss';

interface FilterButtonsProps {
  onSearch: VoidFunction;
  onReset: VoidFunction;
}
export const TokenFilterButtons = ({
  onReset,
  onSearch,
}: FilterButtonsProps) => {
  return (
    <Group placement='end' spacing='0.5em' className='token-filter-buttons'>
      <Tooltip
        className='tippy-tooltip'
        content='Remove filters'
        placement='bottom'
      >
        <Ripple>
          <Row onClick={onReset} className='token-filter-button reset-button'>
            <X strokeWidth={1} size={32} />
          </Row>
        </Ripple>
      </Tooltip>
      <Tooltip
        className='tippy-tooltip'
        content='Apply filters'
        placement='bottom'
      >
        <Ripple>
          <Row onClick={onSearch} className='token-filter-button search-button'>
            <Search strokeWidth={1} size={26} />
          </Row>
        </Ripple>
      </Tooltip>
    </Group>
  );
};

export const OperatorIcon = ({
  operator,
  ...iconProps
}: {
  operator: SearchTokenOperator;
} & IconProps) => {
  return (
    <Switch value={operator}>
      <SwitchCase caseValue={SearchTokenOperator.Like}>
        <Asterisk {...iconProps} />
      </SwitchCase>
      <SwitchCase caseValue={SearchTokenOperator.Equals}>
        <Equal {...iconProps} />
      </SwitchCase>
      <SwitchDefault>{operator}</SwitchDefault>
    </Switch>
  );
};

const suggestionIconProps = {
  className: 'suggestion-default-icon',
  size: 18,
  strokeWidth: 1,
};

export const BooleanOptionsComponents: {
  [val in 'true' | 'false']: React.ReactNode;
} = {
  true: (
    <Group placement='space-between'>
      <Check {...suggestionIconProps} />
      <Text>Yes</Text>
    </Group>
  ),
  false: (
    <Group placement='space-between'>
      <X {...suggestionIconProps} />
      <Text>No</Text>
    </Group>
  ),
};

export const OperatorOptionsComponents: {
  [op in SearchTokenOperator]: React.ReactNode;
} = {
  [SearchTokenOperator.Like]: (
    <Group placement='space-between'>
      <OperatorIcon
        operator={SearchTokenOperator.Like}
        {...suggestionIconProps}
      />
      <Text>(Like)</Text>
    </Group>
  ),
  [SearchTokenOperator.Equals]: (
    <Group placement='space-between'>
      <OperatorIcon
        operator={SearchTokenOperator.Equals}
        {...suggestionIconProps}
      />
      <Text>(Is)</Text>
    </Group>
  ),
};
