import { Token } from 'domain/models';
import { Api } from '../core';

export class AuthorizationService {

  static hasToken(): boolean {
    return !!AuthorizationService.safeGetAuthorization();
  }
  
  static safeGetAuthorization(): Token | undefined {
    try {
      return AuthorizationService.getAuthorization();
    } catch (err) {
      return undefined;
    }
  }
  
  static getAuthorization(): Token {
    try {
      const auth = localStorage.getItem('auth:token');
      return JSON.parse(auth || '') as Token;
    } catch (err) {
      throw new Error(
        'AuthorizationService :: Unable to retrieve authorization!'
      );
    }
  }

  static saveAuthorization(token: Token) {
    // Set where the token has been updated
    token.updated_at = Date.now();
    localStorage.setItem('auth:token', JSON.stringify(token));
  }

  static removeAuthorization() {
    localStorage.removeItem('auth:token');
  }

  public async renew(): Promise<Token> {
    const token = AuthorizationService.getAuthorization();
    try {
      const response = await fetch(Api.baseUrl + Api.auth.renew, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.refresh_token}`
        }
      });

      if (response.status === 200) {
        const data = await response.json();
        AuthorizationService.saveAuthorization(data);
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      throw new Error('AuthorizationService :: Unable to renew token!');
    }
  }
}
