import React from 'react';

import { ParagraphProps } from './Commons';

import './Paragraph.scss';

export const Paragraph = React.forwardRef((props: ParagraphProps, ref: any) => {
  const { className, children, ...otherProps } = props;

  return (
    <p className={`paragraph ${className || ''}`} {...otherProps}>
      {children}
    </p>
  );
});
