import useSWR, { KeyedMutator } from 'swr';

import { Api } from 'domain/core';
import { ChatThread, ExecutionThreads, Message } from 'domain/models';
import { rawFetcher } from './fetcher';
import React from 'react';

export const addComment = async (
  {
    executionId,
    key,
    content,
    parentId,
    isDraft,
    id,
  }: {
    executionId?: string;
    key: string;
    content: string;
    parentId?: number;
    isDraft?: boolean;
    id?: number;
  },
  mutate: () => void
) => {
  const body = {
    executionId,
    key,
    content,
    parentId,
    isDraft,
    id,
  };

  await rawFetcher(`${Api.baseUrl}${Api.messageThread.create}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  mutate();
};

export const persistComment = async (
  {
    executionId
  }: {
    executionId: string;
  }
) => {
  await rawFetcher(`${Api.baseUrl}${Api.messageThread.persist(executionId)}`, {
    method: 'PUT',
  });
};

export const useMessageThread = (
  executionId?: string,
  key?: string
): [
  ChatThread | undefined,
  (content: string, parentId?: number, isDraft?: boolean, id?: number) => Promise<any>,
  KeyedMutator<ChatThread>,
  (id: string) => Promise<void>
] => {

  let url: string | undefined = `${
    Api.baseUrl
  }${Api.messageThread.findByExecutionId(executionId ?? '')}/${encodeURIComponent(key ?? '')}`;

  if (!executionId || !key) {
    url = undefined;
  }

  const { data, mutate } = useSWR<ChatThread>(url);

  const pushMessage = React.useCallback(
    (content: string, parentId?: number, isDraft?: boolean, id?: number) =>
      key
        ? addComment({ executionId, key, content, parentId, isDraft, id }, mutate)
        : Promise.reject('No key provided'),
    [key, mutate]
  );

  const deleteMessage = React.useCallback(
    async (id: string) => {
      if (!key) {
        return;
      }

      await rawFetcher(`${Api.baseUrl}${Api.messageThread.delete}/${id}`, {
        method: 'DELETE',
      });
      mutate();
    },
    [key, mutate]
  );
  
  return [data as ChatThread, pushMessage, mutate, deleteMessage];
};
