import useSWR from 'swr';

import { Page, PageQuery, Workflow, WorkflowConfig } from 'domain/models';
import { PagedQueryParams } from 'domain/utils';
import { Api } from 'domain/core';
import { rawFetcher } from './fetcher';

export const useWorkflows = (
  page: PageQuery = { page: 0, size: 10 },
  q?: string
): Page<Workflow> => {
  const qs = new PagedQueryParams(page, q).getQueryParams();
  const { data } = useSWR<Page<Workflow>>(
    `${Api.baseUrl}${Api.workflows.findAll}?${qs}`
  );
  return data as Page<Workflow>;
};

export const useWorkflow = (id?: string): Workflow => {
  let url: string | null = `${Api.baseUrl}${Api.workflows.findAll}/${id}`;
  if (!id) {
    url = null;
  }
  const { data } = useSWR<Workflow>(url);

  return data as Workflow;
};

export const useWorkflowByProcess = (process?: string): Workflow => {
  const url = process
    ? `${Api.baseUrl}${Api.workflows.findAll}/process/${process}`
    : null;
  const { data } = useSWR<Workflow>(url);
  return data as Workflow;
};

export const useWorkflowDiagram = (workflowName: string): string => {
  let url: string | null = `${Api.baseUrl}${Api.workflows.definition(
    workflowName
  )}`;

  if (!workflowName) {
    url = null;
  }

  const { data } = useSWR<string>(url, (url) =>
    rawFetcher(url).then(r => r.text())
  );

  return data as string;
};

export const useWorkflowConfig = (): [
  WorkflowConfig,
  (config: any) => Promise<Response>,
] => {
  const { data, mutate, } = useSWR<WorkflowConfig>(
    `${Api.baseUrl}${Api.workflows.config}`
  );

  const updateConfig = (config: WorkflowConfig) => {
    return rawFetcher(`${Api.baseUrl}${Api.admin.workflows.config}/filters`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
    }).then((r) => {
      mutate();
      return r;
    });
  };

  return [
    data as WorkflowConfig,
    updateConfig,
  ];
};

export const useWorkflowDiagramImage = (process: string): any => {
  let url: string | null = `${Api.baseUrl}${Api.workflows.diagramImage(
    process
  )}`;
  if (!process) {
    url = null;
  }

  const { data } = useSWR(url, (url) => fetch(url).then((r) => r.blob()));

  return data as any;
};
