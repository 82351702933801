import React from 'react';

import cx from 'classnames';

import { Column, Group, Row } from './Flex';

import './Stepper.scss';
import {
  Check,
  Loader,
  Number0,
  Number1,
  Number2,
  Number3,
  Number4,
  Number5,
  Number6,
  Number7,
  Number8,
  Number9,
} from 'tabler-icons-react';

interface StepperProps {
  active: number;
  className?: string;
  activeClassName?: string;
  loadingClassName?: string;
  completedClassName?: string;
  onStepClick?(index: number): void;
}
export const Stepper = (props: React.PropsWithChildren<StepperProps>) => {
  const {
    active,
    className = '',
    activeClassName = '',
    loadingClassName = '',
    completedClassName = '',
    onStepClick,
    children,
  } = props;

  const length = React.useMemo(
    () => React.Children.count(children),
    [children]
  );

  const activeIndex = React.useMemo(() => {
    return Math.max(Math.min(active, length), 0);
  }, [length, active]);

  const Line = ({ completed }: { completed: boolean }) => {
    return <Row className={`step-line ${cx({ completed })}`} />;
  };

  return (
    <Group
      className={`stepper-wrapper ${className}`}
      placement='start'
      orientation='column'
    >
      <Group className={`stepper ${className}`} placement='space-between'>
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child) && child.type === Step) {
            const isLast = index === length - 1;
            return (
              <>
                <PrivateStep
                  activeClassName={activeClassName}
                  loadingClassName={loadingClassName}
                  completedClassName={completedClassName}
                  {...child.props}
                  index={index}
                  active={activeIndex === index}
                  completed={activeIndex > index}
                  onClick={() => (onStepClick ? onStepClick(index) : null)}
                />
                {!isLast && <Line completed={activeIndex > index} />}
              </>
            );
          }
          return null;
        })}
      </Group>
      {React.Children.map(children, (child, index) => {
        if (
          React.isValidElement(child) &&
          child.type === Step &&
          activeIndex === index
        ) {
          return child.props.children;
        }
      })}
      {/* Como dibujar aquí el contenido del Step activo? */}
    </Group>
  );
};

interface StepProps {
  allowStepClick?: boolean;
  label: React.ReactNode;
  description: React.ReactNode;
  icon?: React.ReactNode;
  completedIcon?: React.ReactNode;
  loadingIcon?: React.ReactNode;
  activeClassName?: string;
  loadingClassName?: string;
  completedClassName?: string;
  disabledClassName?: string;
  loading?: boolean;
  disabled?: boolean;
}
export const Step = (props: React.PropsWithChildren<StepProps>) => {
  return null;
};

interface PrivateStepProps extends StepProps {
  index: number;
  active: boolean;
  completed: boolean;
  onClick?: VoidFunction;
}
const PrivateStep = ({
  index,
  label,
  description,
  active,
  completed,
  loading,
  disabled,
  allowStepClick = true,
  icon,
  completedIcon,
  loadingIcon,
  disabledClassName = '',
  activeClassName = '',
  loadingClassName = '',
  completedClassName = '',
  onClick,
}: React.PropsWithChildren<PrivateStepProps>) => {
  const className = cx('step', {
    [`${activeClassName} step-active`]: active,
    [`${completedClassName} step-completed`]: completed,
    [`${loadingClassName} step-loading`]: loading,
    [`${disabledClassName} step-disabled`]: disabled,
    interactive: allowStepClick,
  });

  const CompletedIcon = React.useCallback((): any => {
    return completedIcon ?? <Check className='step-icon completed-icon' />;
  }, [completedIcon]);

  const LoadingIcon = React.useCallback((): any => {
    return loadingIcon ?? <Loader className='step-icon loading-icon' />;
  }, [loadingIcon]);

  const DefaultIcon = React.useCallback((): any => {
    if (icon) {
      return icon;
    }

    const I = [
      Number0,
      Number1,
      Number2,
      Number3,
      Number4,
      Number5,
      Number6,
      Number7,
      Number8,
      Number9,
    ][index];

    return <I className='step-icon default-icon' />;
  }, [index, icon]);

  const Icon = React.useCallback(() => {
    if (completed) {
      return <CompletedIcon />;
    }

    if (loading) {
      return <LoadingIcon />;
    }

    return <DefaultIcon />;
  }, [completed, loading, CompletedIcon, LoadingIcon, DefaultIcon]);

  return (
    <Row className={className} onClick={allowStepClick ? onClick : undefined}>
      <Column className='step-left-side'>
        <Row className='step-icon-wrapper'>
          <Icon />
        </Row>
      </Column>
      <Column className='step-right-side'>
        <Row className='step-label'>{label}</Row>
        <Row className='step-description'>{description}</Row>
      </Column>
    </Row>
  );
};

export const useStepper = (
  optionsLength: number
): [
  number,
  VoidFunction,
  VoidFunction,
  React.Dispatch<React.SetStateAction<number>>
] => {
  const [active, setActive] = React.useState(0);

  const next = React.useCallback(
    () => setActive((active) => (active < optionsLength ? active + 1 : active)),
    [setActive]
  );

  const previous = React.useCallback(
    () => setActive((active) => (active > 0 ? active - 1 : active)),
    [setActive]
  );

  return [active, next, previous, setActive];
};
