import React from 'react';

import { useElementSize } from 'domain/hooks';

import './Spoiler.scss';
import { Text } from './Text';

interface SpoilerProps {
  maxHeight: number;
  className?: string;
}
export const Spoiler = (props: React.PropsWithChildren<SpoilerProps>) => {
  const [show, setShow] = React.useState(true);

  const [enabled, setEnabled] = React.useState(true);

  const { ref: container, height } = useElementSize();

  const onClick = () => setShow(!show);

  React.useEffect(() => {
    setEnabled(props.maxHeight < height);
    if (props.maxHeight > height) {
      setShow(true);
    }
  }, [height, props.maxHeight, props.children]);

  return (
    <div className={`spoiler ${props.className ?? ''}`}>
      <div
        className='wrapper'
        style={{ height: show ? height : props.maxHeight }}
      >
        <div className='spoiler-content' ref={container}>
          {props.children}
        </div>
      </div>
      {enabled && (
        <Text className='interactive' onClick={onClick} role='button'>
          {show ? 'Hide' : 'Show more'}
        </Text>
      )}
    </div>
  );
};
