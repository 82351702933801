import { PageQuery } from 'domain/models';

export type QueryParam = { key: string; value: any };
export type QueryData = { [key: string]: number | string };

export class BaseQueryParams {
  protected params: QueryParam[] = [];

  constructor(...params: QueryParam[]) {
    this.params = params;
  }

  public getQueryParams(): string {
    const params = new URLSearchParams();

    for (let param of this.params) {
      params.append(param.key, param.value);
    }
    return params.toString();
  }

  public addParam(key: string, value?: any): this {
    if (value !== null && value !== undefined && value !== '') {
      this.params.push({ key, value });
    }

    return this;
  }
}

export class PagedQueryParams extends BaseQueryParams {
  constructor(page: PageQuery & any, q?: string) {
    const args = [];
    if (page.page !== undefined) {
      args.push({ key: 'page', value: page.page });
    }
    if (page.size !== undefined) {
      args.push({ key: 'size', value: page.size });
    }
    if (page.sort !== undefined) {
      args.push({ key: 'sort', value: page.sort });
    }
    if (q !== undefined) {
      args.push({ key: 'q', value: q });
    }
    if (page.filters !== undefined) {
      args.push({ key: 'filters', value: page.filters });
    }
    super(...args);
  }
}
