import React from 'react';

import { MaxLengthIndicator } from './MaxLengthIndicator';
import { TextareaProps } from './Commons';

import './Textarea.scss';
import { useWordCount } from 'domain/hooks/wordCount';

export const Textarea = React.forwardRef((props: TextareaProps, ref: any) => {
  const { className, ...otherProps } = props;

  return <textarea className={`textarea ${className || ''}`} {...otherProps} />;
});

export const LimitedTextarea = React.forwardRef(
  (
    {
      className = '',
      maxLength = 300,
      onChange,
      ...props
    }: TextareaProps & { maxLength?: number },
    ref: any
  ) => {

    const valueLength = useWordCount(props.value);

    const intChange: React.ChangeEventHandler<HTMLTextAreaElement> = (evt) => {
      if (valueLength <= maxLength) {
        onChange?.(evt);
      }
    };

    return (
      <div className={`textarea-limit-wrapper ${className}`}>
        <Textarea {...props} ref={ref} onChange={intChange} />
        <MaxLengthIndicator currentLength={valueLength} maxLength={maxLength} />
      </div>
    );
  }
);
