import React from 'react';

import { Api } from 'domain/core';
import { QAUseCaseAttachment } from 'domain/models';
import { fetcher, rawFetcher } from './fetcher';
import { downloadBlob } from 'domain/utils';

// USE_CASE_IMAGE - Download and delete attachments
export const useUseCaseAttachments = (
  ids: string[] | null,
  qar?: number
): [QAUseCaseAttachment[], (id: string) => Promise<any>] => {
  const [attachments, setAttachments] = React.useState<QAUseCaseAttachment[]>(
    []
  );

  const deleteAttachment = React.useCallback(
    (id: string) => {
      const url = `${Api.baseUrl}${Api.useCaseAttachments.delete(qar ?? -1, id)}`;

      return fetcher(url, { method: 'DELETE' });
    },
    [qar]
  );

  React.useEffect(() => {
    if (qar) {
      const url = `${Api.baseUrl}${Api.useCaseAttachments.list(qar, ids || [])}`;

      fetcher(url).then((att) => setAttachments(att.content));
    }
  }, [ids, qar]);

  return [attachments, deleteAttachment];
};

// USE_CASE_IMAGE - Upload attachment
export const useCaseAttachmentUploader = (qar?: number) => {
  return React.useCallback(
    (file: File) => {
      if (qar) {
        const url = `${Api.baseUrl}${Api.useCaseAttachments.upload(qar)}`;

        const formData = new FormData();
        formData.append('file', file, file.name);

        // @ts-ignore
        return rawFetcher(url, { method: 'POST', body: formData, headers: {
          'Content-Type': null,
        } })
          .then((r) => r.json());
      }
    },
    [qar]
  );
};

// USE_CASE_IMAGE - Download attachment
export const downloadUseCaseAttachment = (attachment: QAUseCaseAttachment) => {
  const url = `${Api.baseUrl}${attachment.url}`;

  rawFetcher(url)
    .then((r) => r.blob())
    .then((blob) => downloadBlob(attachment.name, blob));
};
