import React from 'react';

import Skeleton from 'react-loading-skeleton';

import {ErrorBoundary, HeaderField, Row, SecurePortal, Text,} from 'components';
import {Page} from 'domain/models';

import './ListComponents.scss';
import {formatDateTime} from "domain/utils";

export const ListWrapperContext = React.createContext<{
  id: number | string;
  editable?: boolean;
  filterable?: boolean;
  showFilter?: boolean;
  fields?: HeaderField[];
  dataPage?: Page<any>;
  setShowFilter?(show: boolean): void;
}>({
  id: Date.now(),
  editable: false,
  filterable: false,
});

/**
 * Element rendered into List component. It automatically hides or shows itself based on data
 * @param
 * @returns
 */
export const NoElements = ({ children }: React.PropsWithChildren<{}>) => {
  const { id } = React.useContext(ListWrapperContext);
  return (
    <SecurePortal id={`${id}-no-elements-container`}>{children}</SecurePortal>
  );
};

interface DefaultCellValueProps {
  title?: string;
  className?: string;
}
/**
 * Element used to wrap the value renderer on each row cell
 * @param
 * @returns
 */
export const DefaultCellValue = React.forwardRef(
  (
    {
      title,
      className = '',
      children,
    }: React.PropsWithChildren<DefaultCellValueProps>,
    ref: any
  ) => (
    <span className={`element-cell-value ${className}`} title={title} ref={ref}>
      {children}
    </span>
  )
);

export const DateCellValue = React.forwardRef(
  (
      {
          title,
          className = '',
      }: DefaultCellValueProps,
      ref: any
  ) => (
      <DefaultCellValue title={formatDateTime(title)} className={className} ref={ref}>
          {formatDateTime(title)}
      </DefaultCellValue>
  )
)

interface ActionCellValueProps {
  className?: string;
}
/**
 * Like DefaultCellValue but for actions at the end of the row
 * @param
 * @returns
 */
export const ActionCellValue = ({
  className = '',
  children,
}: React.PropsWithChildren<ActionCellValueProps>) => (
  <Row className={`actions-cell-value ${className}`}>{children}</Row>
);

/**
 * Action icon container. Meant to be renderer inside ActionCellValue
 * @param
 * @returns
 */
export const ActionIconContainer = ({
  className = '',
  onClick,
  children,
  ...extra
}: React.PropsWithChildren<{ className?: string; onClick?: VoidFunction }>) => (
  <Row
    className={`action-icon-container ${className}`}
    onClick={(evt) => {
      evt.stopPropagation();
      onClick?.();
    }}
    {...extra}
  >
    {children}
  </Row>
);

/**
 *  Component that only renders the children passed as props. It also declare a context to
 *  syncronize id dependant elements like NoElements
 */
export const ListWrapper = ({
  children,
  id,
  editable,
  filterable,
  fields,
  data,
  initialShowFilter,
}: React.PropsWithChildren<{
  id?: string | number;
  editable?: boolean;
  filterable?: boolean;
  fields?: HeaderField[];
  data?: Page<any>;
  initialShowFilter?: boolean;
}>) => {
  const _id = React.useMemo(() => id ?? Date.now(), [id]);
  const [showFilter, setShowFilter] = React.useState(initialShowFilter ?? false);

  return (
    <ListWrapperContext.Provider
      value={{
        id: _id,
        editable,
        filterable,
        showFilter,
        fields,
        dataPage: data,
        setShowFilter,
      }}
    >
      {children}
    </ListWrapperContext.Provider>
  );
};

const IntCellLoader = () => {
  return <Skeleton width={50} height={20} />;
};

const IntCellError = () => {
  return <Text> - </Text>;
};

interface CellLoaderProps {
  suspenseFallback?: React.ReactNode;
  errorBoundaryFallback?: React.ReactNode;
}
/**
 *
 */
export const CellLoader = (props: React.PropsWithChildren<CellLoaderProps>) => {
  return (
    <React.Suspense fallback={props.suspenseFallback ?? <IntCellLoader />}>
      <ErrorBoundary fallback={props.errorBoundaryFallback ?? <IntCellError />}>
        {props.children}
      </ErrorBoundary>
    </React.Suspense>
  );
};
