import React from 'react';

import ReactDateTimePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Row } from './Flex';
import { mergeRefs } from 'domain/utils';
import { Calendar } from 'tabler-icons-react';

import 'react-datepicker/dist/react-datepicker.css';

import './Input.scss';
import './DateTimePicker.scss';

export const DateTimePicker = React.forwardRef(
  ({ className, ...props }: ReactDatePickerProps, ref: any) => {
    const datePickerRef = React.useRef<ReactDateTimePicker>(null);

    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (datePickerRef.current) {
        switch (e.keyCode) {
          case 9: {
            datePickerRef.current.setOpen(false);
            datePickerRef.current.setBlur();
          }
        }
      }
    };

    return (
      <Row className='date-picker-wrapper'>
        <ReactDateTimePicker
          ref={mergeRefs(ref, datePickerRef)}
          showPopperArrow={false}
          className={`date-picker input ${className}`}
          onKeyDown={onKeyPress}
          {...props}
        />
        <Calendar className='calendar-icon' />
      </Row>
    );
  }
);
