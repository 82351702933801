import useSWR from 'swr';
import React from 'react';

import { Execution, Page, PageQuery, UserEmailGroup } from 'domain/models';
import { PagedQueryParams } from 'domain/utils';
import { Api } from 'domain/core';
import { rawFetcher } from './fetcher';

export const useExecutions = (
  page: PageQuery = { page: 0, size: 10 },
  q?: string,
  filters?: string[]
): Page<Execution> => {
  const qs = new PagedQueryParams(page, q);
  if (filters) {
    filters.forEach((f) => qs.addParam('filters', f));
  }
  const { data } = useSWR<Page<Execution>>(
    `${Api.baseUrl}${Api.executions.findAll}?${qs.getQueryParams()}`
  );
  return data as Page<Execution>;
};

export type UseDeleteExecution = () => Promise<boolean>;
export const useDeleteExecution = (process: string): UseDeleteExecution => {
  const deleteUrl = `${Api.baseUrl}${Api.executions.findAll}/${process}`;

  return () =>
    rawFetcher(deleteUrl, {
      method: 'DELETE',
    }).then((r) => r.status >= 200 && r.status < 300) as Promise<boolean>;
};

export const useExecution = (process?: string): Execution => {
  const { data } = useSWR<Execution>(
    `${Api.baseUrl}${Api.executions.findAll}/${process}`
  );
  return data as Execution;
};

export const useUpdateExecution = (executionId?: string) => {
  return React.useCallback(
    (variables: Partial<Execution['variables']>) => {
      if (executionId) {
        return rawFetcher(
          `${Api.baseUrl}${Api.executions.findAll}/${executionId}/variables`,
          {
            method: 'PUT',
            body: JSON.stringify(variables),
            headers: { 'Content-Type': 'application/json' },
          }
        );
      }
    },
    [executionId]
  );
};

export const useCreateExecution = () => {
  const createExecutionFn = async (execution: Omit<Execution, 'state'>) => {
    return await rawFetcher(`${Api.baseUrl}${Api.executions.create}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(execution),
    });
  };
  return createExecutionFn;
};

export const useExecutionGroups = (
  process?: string
): [
  UserEmailGroup[] | undefined,
  (groups: UserEmailGroup[]) => Promise<void>,
  boolean
] => {
  const [isLoading, setIsLoading] = React.useState(false);
  const url = process
    ? `${Api.baseUrl}${Api.executions.findAll}/${process}/groups`
    : null;

  const { data } = useSWR<UserEmailGroup[]>(url);

  const update = async (groups: UserEmailGroup[]) => {
    if (!process || !url) {
      return;
    }

    setIsLoading(true);

    try {
      await rawFetcher(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(groups),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return [process ? (data as UserEmailGroup[]) : undefined, update, isLoading];
};

export const reopenExecution = (executionId: string) => {
  return rawFetcher(`${Api.baseUrl}${Api.executions.reopen(executionId)}`, {
    method: 'PUT',
  });
};

export const requestReopenExecution = (executionId: string) => {
  return rawFetcher(
    `${Api.baseUrl}${Api.executions.requestReopen(executionId)}`,
    {
      method: 'PUT',
    }
  );
};
