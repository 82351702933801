import Tippy from '@tippyjs/react';

import { Group } from 'components/Flex';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../domain/models';
import { useCalendarEvents } from '../../domain/swr/useCalendar';

import { CalendarDay } from './CalendarDay';
import { CalendarMonth } from './CalendarMonth';
import { CalendarNavigation } from './CalendarNavigation';

import { CalendarDayTooltip, CalendarEventTooltip } from './CalendarTooltips';
import { CalendarWeekDays } from './CalendarWeekDays';

import {
  CalendarEntry,
  CalendarEntryEvent,
  CalendarEvent,
  CalendarEventMs,
  CalendarUtils,
  useCalendarRenderEntries,
} from './utils';

interface CalendarProps {
  events: CalendarEvent<any>[];
  date: Date;
  className?: string;
  allowCreation?: boolean;
  onCreateEvent?(event: CalendarEventMs): void;
  onDateChange(newDate: Date): void;
  onDayClick?: (
    date: Date,
    dateEntry: CalendarEntry<any>,
    element: HTMLElement
  ) => void;
  onEventClick?: (
    date: Date,
    event: CalendarEntryEvent<any>,
    element: HTMLElement
  ) => void;
  getDayTooltipRenderContent?: (
    date: Date,
    dateEntry: CalendarEntry<any>
  ) => React.ReactNode;
  getEventTooltipRenderContent?: (
    date: Date,
    event: CalendarEntryEvent<any>
  ) => React.ReactNode;
}
export const Calendar = (props: CalendarProps) => {
  const renderDates = useCalendarRenderEntries(props.date, props.events);

  const [hoveredEvent, setHoveredEvent] = React.useState<
    CalendarEntryEvent<any> | undefined
  >();
  const [selectedEntry, setSelectedEntry] = React.useState<
    CalendarEntry<any> | CalendarEntryEvent<any> | undefined
  >();
  const [activeElement, setActiveElement] = React.useState<
    HTMLElement | undefined
  >();
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>();

  const [showTooltip, setShowTooltip] = React.useState(false);

  const onClick = (
    date: Date,
    entry: CalendarEntry<any> | CalendarEntryEvent<any>,
    element: HTMLElement
  ) => {
    setSelectedEntry(entry);
    setActiveElement(element);
    setSelectedDate(date);
    setShowTooltip(true);

    if (CalendarUtils.isCalendarEntry(entry)) {
      props.onDayClick?.(props.date, entry, element);
    } else if (CalendarUtils.isCalendarEntryEvent(entry)) {
      props.onEventClick?.(props.date, entry, element);
    }
  };

  const closeTooltip = () => {
    setActiveElement(undefined);
    setSelectedEntry(undefined);
    setSelectedDate(undefined);
    setShowTooltip(false);
  };

  const TooltipContent = React.useCallback(() => {
    if (selectedEntry && selectedDate) {
      if (CalendarUtils.isCalendarEntry(selectedEntry)) {
        return (
          <CalendarDayTooltip
            date={selectedDate}
            dateEntry={selectedEntry}
            onClose={closeTooltip}
            events={props.events}
            allowCreation={props.allowCreation ?? false}
            setEvents={props.onCreateEvent ?? (() => {})}
          />
        );
      } else if (CalendarUtils.isCalendarEntryEvent(selectedEntry)) {
        return (
          <CalendarEventTooltip
            date={selectedDate}
            event={selectedEntry}
            onClose={closeTooltip}
          />
        );
      }
    }
    return null;
  }, [
    selectedDate,
    selectedEntry,
    props.getDayTooltipRenderContent,
    props.getEventTooltipRenderContent,
  ]);

  return (
    <Group
      className={`calendar ${props.className ?? ''}`}
      orientation='column'
      spacing='0.5em'
    >
      <Tippy
        content={<TooltipContent />}
        reference={activeElement}
        appendTo={document.body}
        interactive
        animation='scale'
        visible={showTooltip}
        placement='bottom'
        onClickOutside={closeTooltip}
      />
      <CalendarNavigation date={props.date} onChange={props.onDateChange} />
      <CalendarMonth>
        <CalendarWeekDays />
        {renderDates.map((entry) => {
          return (
            <CalendarDay
              date={props.date}
              dateEntry={entry}
              hoveredEvent={hoveredEvent}
              onDayClick={onClick}
              onEventClick={onClick}
              onEventHover={setHoveredEvent}
              key={entry.date.valueOf()}
            />
          );
        })}
      </CalendarMonth>
    </Group>
  );
};
