import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Router } from 'pages/Router';

import appStore from 'domain/store/app.store';
import { UseProductionMessage } from 'pages/shared/UseProductionMessage';

import './App.scss';

const App = () => {
  return (
    <Provider store={appStore}>
      <BrowserRouter>
        <UseProductionMessage />
        <Router />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
