import React from 'react';

import { Row } from 'components/Flex';
import { Text } from 'components/Text';

import './CalendarWeekDays.scss';

export const CalendarWeekDays = () => {
  const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  return (
    <>
      {weekDays.map((day) => (
        <Row className='calendar-week-day' key={day}>
          <Text className='calendar-week-day-text'>{day}</Text>
        </Row>
      ))}
    </>
  );
};
