import React from 'react';

import { Grid, GridItem, Group } from 'components/Flex';

import { ListWrapperContext } from './ListComponents';

import './ListFilter.scss';

interface FilterContainerProps {
  className?: string;
}
/**
 * Filter container responsible to expand and collapse itself
 * @param
 * @returns
 */
export const ListFilterContainer = ({
  className = '',
  children,
}: React.PropsWithChildren<FilterContainerProps>) => {
  const { filterable, showFilter } = React.useContext(ListWrapperContext);

  return filterable ? (
    <div
      className={`filter-container ${className} ${
        showFilter === true ? 'show' : 'hide'
      }`}
    >
      {children}
    </div>
  ) : null;
};

export const ListFilterGroup = ({
  children,
  className = '',
}: React.PropsWithChildren<FilterContainerProps>) => {
  return <Group className={`list-filter-group ${className}`}>{children}</Group>;
};
