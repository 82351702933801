import React from 'react';

import { DivProps } from './Commons';

import { useCollapse, useForceUpdate } from 'domain/hooks';

type CollapsibleProps = DivProps & {
  in: boolean;
};
export const Collapsible = (props: CollapsibleProps) => {
  const forceUpdate = useForceUpdate();

  React.useEffect(() => forceUpdate, [props.children]);

  const getCollapseProps = useCollapse({
    opened: props.in,
    transitionDuration: 150,
    transitionTimingFunction: 'ease-in-out',
    onTransitionEnd: () => {},
    children: props.children,
  });

  return (
    <div
      className={`collapsible ${props.className ?? ''}`}
      ref={props.ref}
      {...getCollapseProps({})}
    >
      {props.children}
    </div>
  );
};
