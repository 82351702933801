import Tippy, { TippyProps } from '@tippyjs/react';
import { useIsMobile } from 'domain/hooks';
import React from 'react';

import '../assets/styles/partials/_tippy.scss';

type TooltipProps = TippyProps;

export const Tooltip = React.forwardRef((props: TooltipProps, ref: any) => {
  const isMobile = useIsMobile();

  const { className = 'tippy-tooltip', ...rest } = props;

  return isMobile ? (
    <>{props.children}</>
  ) : (
    <Tippy
      {...rest}
      ref={ref}
      arrow
      className={`tippy-tooltip ${className}`}
      animation='scale'
    />
  );
});
