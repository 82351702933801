import { TFunction } from 'react-i18next';

export interface SelectOption<T> {
  value: T;
  label: string;
}

export type AsSelectConfig = {
  lowercase?: boolean;
};

export const asSelect = <T>(
  t: TFunction,
  options: T[],
  prefix: string,
  config: AsSelectConfig = {}
): SelectOption<T>[] => {
  if (!options) {
    console.warn(`${prefix} options is undefined`);
    return [];
  }
  let _parts = [prefix + '.', ''];
  if (prefix.includes('{key}')) {
    _parts = prefix.split('{key}');
  }
  return options.map((option) => {
    let key = `${_parts[0]}${option}${_parts[1]}`;
    if (config.lowercase) {
      key = key.toLowerCase();
    }
    return {
      value: option as T,
      label: t(key, {
        defaultValue: option,
      }),
    };
  });
};

export const inOptions = <T>(
  options: SelectOption<T>[] = [],
  value: T | T[],
  compareFn?: (t1: T, t2: T) => boolean,
  multiple?: boolean
): SelectOption<T> | SelectOption<T>[] | undefined => {
  const filterFn = Array.isArray(value)
    ? (option: SelectOption<T>) => {
      const occ = value.find((v) => {
        if (compareFn) {
          return compareFn(option.value, v);
        }
        return option.value === v;
      });
      return Boolean(occ);
    }
    : (option: SelectOption<T>) => {
      if (compareFn) {
        return compareFn(option.value, value);
      }
      return option.value === value;
    };
  return multiple ? options.filter(filterFn) : options.find(filterFn);
};
