import React from 'react';

export type FixedSearchToken = {
  code: string;
  label: string;
  type: SearchTokenValueType.QuickFilter;
  value: SearchTokenOption;
};

export type SearchToken =
  | FixedSearchToken
  | {
      code: string;
      label: string;
      operators: SearchTokenOperator[];
      options?: SearchTokenOption[];
      type: SearchTokenValueType;
    };

export type SearchTokenResult = {
  code?: string;
  operator?: SearchTokenOperator;
  value?: SearchTokenOption;
  token: SearchToken;
};

export type SearchTokenOption = {
  label: string;
  value: any;
  component?: React.ReactNode;
};

export enum SearchTokenOperator {
  Equals = 'equals',
  Like = 'Like',
}

export const SearchTokenOperatorMap = {
  [SearchTokenOperator.Equals]: 'EQ',
  [SearchTokenOperator.Like]: 'LIKE',
};

export const SearchTokenOperatorList = [
  SearchTokenOperator.Equals,
  SearchTokenOperator.Like,
];

export enum SearchTokenValueType {
  Select = 'select',
  FreeText = 'freeText',
  Boolean = 'boolean',
  QuickFilter = 'quick_filter',
}

export enum TokenFilterStep {
  SelectField,
  SelectOperator,
  SelectValue,
}
