import { Token } from 'domain/models';

export const isValid = (token: Token): [boolean, number] => {
  if (!token || !token.updated_at) {
    return [false, 0];
  }

  const msDate = new Date(token.updated_at + token.expires_in * 1000).valueOf();
  const msNow = Date.now();
  const remaining = msDate - msNow;
  return [remaining > 0, remaining];
};
