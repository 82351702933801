import React from 'react';

import { LinkProps } from './Commons';

import './Link.scss';

export const Link = React.forwardRef((props: LinkProps, ref: any) => {
  const { className, ...otherProps } = props;

  return (
    <a className={`link ${className || ''}`} ref={ref} {...otherProps}>
      {props.children}
    </a>
  );
});
