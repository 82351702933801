import React from "react";

import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { X } from "tabler-icons-react";

import { Row, Column } from "./Flex";
import { Button } from "./Button";
import { Responsive } from "./ResponsiveContainer";

import "./Modal.scss";
import { SecurePortal } from "./SecurePortal";

const useDebounceToggle = (initialValue: any, nextValue: any, time: number) => {
  const [val, setVal] = React.useState(initialValue);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setVal(nextValue);
    }, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [nextValue, time]);

  return val;
};

interface ModalProps {
  dismissable?: boolean;
  className?: string;
  header: React.ReactNode;
  footer?: React.ReactNode;
  onClose: VoidFunction;
  h?: string;
}
const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const show = useDebounceToggle(false, true, 0);

  const backdrop = React.useRef<HTMLDivElement>();

  const dismissOnClick = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (evt.target === backdrop?.current) {
      if (props.dismissable) {
        props.onClose && props.onClose();
      }
    }
  };

  return (
    <CSSTransition
      classNames="modal-transition"
      timeout={200}
      unmountOnExit
      mountOnEnter
      in={show}
    >
      <Responsive>
        <Row
          className={`modal-backdrop ${props.className || ""}`}
          ref={backdrop}
          onClick={dismissOnClick}
        >
          <Column className={`modal`}>
            <Header close={props.onClose}>{props.header}</Header>
            <Body>{props.children}</Body>
            <Footer>{props.footer}</Footer>
          </Column>
        </Row>
      </Responsive>
    </CSSTransition>
  );
};

const Header = ({
  close,
  children,
}: React.PropsWithChildren<{ close: VoidFunction }>) => {
  return (
    <Row className="modal-header">
      <Row className="modal-header-content">{children}</Row>
      <div className="w-[24px] h-[24px]">
        <X
          onClick={close}
          size={24}
          strokeWidth={1}
          className="modal-close-icon"
        />
      </div>
    </Row>
  );
};

const Footer = ({ children }: React.PropsWithChildren<{}>) => {
  return children ? <Row className="modal-footer">{children}</Row> : null;
};

const Body = ({ children }: React.PropsWithChildren<{}>) => {
  return <Column className="modal-body">{children}</Column>;
};

const Portal = ({ children }: React.PropsWithChildren<{}>) => {
  return <SecurePortal id="modal-portal">{children}</SecurePortal>;
};

export interface ConfirmationModalProps {
  title: string;
  message: string | React.ReactNode;
  className?: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}
export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      className={`confirmation-modal ${props.className || ""}`}
      header={props.title}
      footer={
        <Row className="confirmation-modal-actions justify-between">
          <Button onClick={props.onCancel}>{t("common:actions.cancel")}</Button>
          <Button onClick={props.onConfirm}>
            {t("common:actions.confirm")}
          </Button>
        </Row>
      }
      dismissable={false}
      onClose={props.onCancel}
    >
      <Row className="confirmation-modal-content">{props.message}</Row>
    </Modal>
  );
};

const FullScreenModal = (props: React.PropsWithChildren<ModalProps>) => {
  // Disable body scroll
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 bg-white z-[999] overflow-auto`}
      onClick={props.onClose}
    >
      {/* Scroll area */}
      <div className="block" style={{ padding: "1rem" }}>
        {props.children}
      </div>
    </div>
  );
};

Modal.Portal = Portal;

export { Modal, FullScreenModal };
