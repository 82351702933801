export class PeriodUtils {
  public static fromString(value?: string): Duration | undefined {
    if (!value) {
      return undefined;
    }
    // Check if it is a valid ISO 8601 duration
    if (value.startsWith('P')) {
      return fromISO(value);
    }
    return undefined;
  }

  public static empty(): Duration {
    return fromISO('P0D');
  }

  public static toString(value: Duration): string {
    if (!value) {
      return 'P0D';
    }

    let result = 'P';
    if (isCorrectPeriod(value.years)) {
      result += `${value.years}Y`;
    }
    if (isCorrectPeriod(value.months)) {
      result += `${value.months}M`;
    }
    if (isCorrectPeriod(value.days)) {
      result += `${value.days}D`;
    }
    let time = '';
    if (isCorrectPeriod(value.hours)) {
      time += `${value.hours}H`;
    }
    if (isCorrectPeriod(value.minutes)) {
      time += `${value.minutes}M`;
    }
    if (isCorrectPeriod(value.seconds)) {
      time += `${value.seconds}S`;
    }
    if (time) {
      result += `T${time}`;
    }
    return result;
  }
}

function fromISO(value: string): Duration {
  // Parse, starts with P
  const duration = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  // Remove P
  let remaining = value.substring(1);

  // Split [ val, TOKEN, val, TOKEN, ... ]
  const tokens = ['Y', 'M', 'D', 'H', 'M', 'S'];
  const parts = [];
  let current = '';
  for (let i = 0; i < remaining.length; i++) {
    const char = remaining.charAt(i);
    if ('T' === char) {
      parts.push(char);
      continue;
    }
    if (tokens.includes(char)) {
      parts.push(current);
      parts.push(char);
      current = '';
    } else {
      current += char;
    }
  }
  // As token is always after value, no need to add last value
  for (let i = 0; i < parts.length; i+=2) {
    const unit = parts[i + 1];
    const value = parseInt(parts[i]);
    switch (unit) {
      case 'Y':
        duration.years = value;
        break;
      case 'M':
        duration.months = value;
        break;
      case 'D':
        duration.days = value;
        break;
    }
    if ('T' === unit) {
      break; // End of date part, start of time part
    }
  }
  // Time part
  for (let i = parts.indexOf('T') + 1; i < parts.length; i+=2) {
    const unit = parts[i + 1];
    const value = parseInt(parts[i]);
    switch (unit) {
      case 'H':
        duration.hours = value;
        break;
      case 'M':
        duration.minutes = value;
        break;
      case 'S':
        duration.seconds = value;
        break;
    }
  }
  return duration;
}

function isCorrectPeriod(value: number | undefined) {
  return value && value > 0 && !isNaN(value);
}
