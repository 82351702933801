import { createReducer } from 'deox';

import * as Actions from './root.actions';

import { RootStore, Notification } from 'domain/models';

export const initialState: RootStore = {
  notifications: [],
  modals: [],
};

export const rootReducer = createReducer(initialState, (handle) => [
  handle(Actions.pushNotification, (state, { payload }) => ({
    ...state,
    notifications: state.notifications.concat(
      Object.freeze({ ...payload, innerKey: Date.now() })
    ),
  })),
  handle(Actions.removeNotification, (state, { payload }) => ({
    ...state,
    notifications: state.notifications.filter((n: Notification) => {
      return n.innerKey !== payload;
    }),
  })),
  handle(Actions.pushModal, (state, { payload }) => ({
    ...state,
    modals: state.modals.concat(payload.modal),
  })),
  handle(Actions.popModal, (state) => ({
    ...state,
    modals: state.modals.slice(1, state.modals.length),
  })),
]);
