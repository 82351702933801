// Esto se hace para poder mockear las funciones que se llaman dentro de otras funciones
// que están en el mismo módulo.
// @see https://stackoverflow.com/a/55193363
import { QAR } from 'domain/models';
import * as CommonUtils from '.';

export const range = (length: number, from: number = 0): number[] =>
  Array.from(new Array(length), (_, i) => from + i);

export const assert = (
  condition: boolean,
  message: string = 'Assertion failed'
): void => {
  if (!condition) {
    throw new Error(message);
  }
};

export const mergeRefs = (...refs: any[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  waitFor: number
) => {
  let timeout: any = 0;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

export const downloadBlob = (filename: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  CommonUtils.triggerLinkClick(url, filename);
};

export const urlToBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  if (response.body?.constructor.name === 'Blob') {
    return await response.blob();
  }

  if (response.body?.constructor.name === 'ReadableStream') {
    const stream: ArrayBuffer = await response.arrayBuffer();
    return new Blob([new Uint8Array(stream, 0, stream.byteLength)]);
  }

  const text = await response.text();
  return new Blob([text]);
};

export const triggerLinkClick = (url: string, filename: string): void => {
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const wait = (time: number): Promise<void> => {
  return new Promise((res) => {
    let tm = setTimeout(() => {
      res();
      clearTimeout(tm);
    }, time);
  });
};

export const strContains = (
  target: string,
  crit: string,
  ignoreCase = true
): boolean => {
  if (ignoreCase) {
    return target.toLocaleLowerCase().includes(crit.toLocaleLowerCase());
  } else {
    return target.includes(crit);
  }
};

export const arrayMoveMutate = <T>(
  array: T[],
  from: number,
  to: number
): void => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = <T>(array: T[], from: number, to: number): T[] => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

export const createId = (prefix: string = '', suffix: string = '') => {
  const rand = Math.random().toString(36).slice(2, 11);

  return `${prefix}${rand}${suffix}`;
};

export const getLabelPathValue = (qar: QAR, labelPath: string) => {
  if (!qar.data) {
    return undefined;
  }

  let path = labelPath.split('/');
  path = path.slice(1, path.length);

  let obj = qar.data;

  for (let pathEntry of path) {
    if (obj) {
      obj = obj[pathEntry];
    }
  }

  return obj;
};
