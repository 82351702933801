import React from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { ChevronLeft } from 'tabler-icons-react';

import { FullPage } from './FullPage';
import { Column, Row } from './Flex';
import { H } from './Heading';
import { SecurePortal } from './SecurePortal';

import { useId } from 'domain/hooks';

import './BaseLayout.scss';

const BaseLayoutContext = React.createContext<string>('');
interface BaseLayoutProps {
  pageTitle: string;
  className?: string;
}
const Layout = ({
  pageTitle,
  className,
  children,
}: React.PropsWithChildren<BaseLayoutProps>) => {
  const layoutId = useId('base_layout_');

  return (
    <BaseLayoutContext.Provider value={layoutId}>
      <FullPage className={`base-layout ${className ?? ''}`}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Row className='base-layout-header'>
          <div
            className='base-layout-header-left'
            id={`${layoutId}-header-left`}
          />
          <div
            className='base-layout-header-center'
            id={`${layoutId}-header-center`}
          />
          <div
            className='base-layout-header-right'
            id={`${layoutId}-header-right`}
          />
        </Row>
        <Column className='base-layout-content'>{children}</Column>
      </FullPage>
    </BaseLayoutContext.Provider>
  );
};

const HeaderLeft = ({ children }: React.PropsWithChildren<{}>) => {
  const layoutId = React.useContext(BaseLayoutContext);
  return <SecurePortal id={`${layoutId}-header-left`}>{children}</SecurePortal>;
};

const HeaderCenter = ({ children }: React.PropsWithChildren<{}>) => {
  const layoutId = React.useContext(BaseLayoutContext);
  return (
    <SecurePortal id={`${layoutId}-header-center`}>{children}</SecurePortal>
  );
};

const HeaderRight = ({ children }: React.PropsWithChildren<{}>) => {
  const layoutId = React.useContext(BaseLayoutContext);
  return (
    <SecurePortal id={`${layoutId}-header-right`}>{children}</SecurePortal>
  );
};

const Title = ({
  title,
  backButton = true,
  onBackClick,
}: {
  title: string;
  backButton?: boolean;
  onBackClick?: VoidFunction;
}) => (
  <HeaderLeft>
    {backButton && <BackButton onClick={onBackClick} />}
    <H.H3 className='base-layout-page-title' title={title}>
      {title}
    </H.H3>
  </HeaderLeft>
);

const BackButton = ({ onClick }: { onClick?: VoidFunction }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const intOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      let path = (state as { from: string })?.from ?? '../';
      navigate(path);
    }
  };

  return (
    <Row className='back-button-container' title='Go back' onClick={intOnClick}>
      <ChevronLeft strokeWidth={1} size={35} className='back-button-icon' />
    </Row>
  );
};

const BLSkeleton = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <FullPage className='base-layout skeleton'>
      <Row className='base-layout-header skeleton'>
        <Skeleton className='skeleton' width={300} height={40} />
      </Row>
      {children}
    </FullPage>
  );
};

export const BaseLayout = {
  Layout,
  HeaderLeft,
  HeaderCenter,
  HeaderRight,
  Title,
  Skeleton: BLSkeleton,
};
