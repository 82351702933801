import React from 'react';

import { Column, Grid, GridItem, Row } from 'components';

import Skeleton from 'react-loading-skeleton';

import { HeaderField } from './ListHeader';

import { range } from 'domain/utils';

import './Skeletons.scss';

interface ListSkeletonProps {
  fields?: HeaderField[];
  count?: number;
}
export const ListSkeleton = ({ fields, count }: ListSkeletonProps) => {
  const Skl = () =>
    fields && fields.length > 0 ? (
      <ListElementSkeleton fields={fields} />
    ) : (
      <DefaultListElementSkeleton />
    );

  return (
    <Column className='list-skeleton'>
      {range(count ?? 10).map((i) => (
        <Skl key={i} />
      ))}
    </Column>
  );
};

const ListElementSkeleton = ({ fields }: { fields: HeaderField[] }) => {
  const cols = fields.reduce((a, f) => a + f.col, 0);
  return (
    <Grid className='list-element-skeleton with-fields' columns={cols}>
      {fields.map((field, i) => (
        <GridItem col={field.col} className='row-cell-skeleton' key={i}>
          <Skeleton
            width='100%'
            height='100%'
            containerClassName='skeleton-cell-wrapper'
          />
        </GridItem>
      ))}
    </Grid>
  );
};

const DefaultListElementSkeleton = () => (
  <Row className='list-element-skeleton  without-fields'>
    <Skeleton
      width='100%'
      height='100%'
      containerClassName='skeleton-cell-wrapper'
    />
  </Row>
);
