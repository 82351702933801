import { Api } from "domain/core";
import { rawFetcher } from "domain/swr/fetcher";
import { downloadBlob } from "./common.utils";

export const downloadExcelGenerator = (api_url: string, qs: string) => {
    const export_url = `${Api.baseUrl}${api_url}/export?${qs}`;
    const doExport = () => {
        rawFetcher(export_url).then(x => x.blob())
            .then(s => {
                downloadBlob(
                    "export.xlsx",
                    s
                );
            })
    };
    return doExport;
}