import useSWR from 'swr';

import { Family, Page, PageQuery } from 'domain/models';
import { Api } from 'domain/core';
import { PagedQueryParams } from 'domain/utils';

export const useFamilies = (
  page: PageQuery = { page: 0, size: 10 },
  q: string = ''
): Page<Family> => {

  const qs = new PagedQueryParams(page, q).getQueryParams();
  const { data } = useSWR<Page<Family>>(
     `${Api.baseUrl}${Api.families.findAll}?${qs}`
  );

  return data as Page<Family>;
};
