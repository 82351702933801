import React from 'react';

import useSWR from 'swr';

import { Api } from 'domain/core';
import { Page, PageQuery, QAR, Schema } from 'domain/models';
import { PagedQueryParams } from 'domain/utils';
import { rawFetcher } from './fetcher';
import { downloadExcelGenerator } from 'domain/utils/downloadExcelGenerator';

export const useQARs = (
  page: PageQuery = { page: 0, size: 10, sort: 'id,desc' },
  q?: string,
  filters?: string[]
): [Page<QAR>, VoidFunction] => {
  const qs = new PagedQueryParams(page);
  if (filters) {
    filters.forEach((f) => qs.addParam('filters', f));
  }
  let url = `${Api.baseUrl}${Api.qars.findAll}?${qs.getQueryParams()}`;
  const { data } = useSWR<Page<QAR>>(url);
  return [
    data as Page<QAR>,
    downloadExcelGenerator(Api.qars.findAll, qs.getQueryParams()),
  ];
};

export const useQARVersions = (
  qarId?: number,
  page: PageQuery = { page: 0, size: 10 }
): [Page<QAR>] => {
  const qs = new PagedQueryParams(page).getQueryParams();
  let url:
    | string
    | null = `${Api.baseUrl}${Api.qars.findVersions}?${qs}&filters=[EQ][id]${qarId}`;

  if (!qarId) {
    url = null;
  }

  const { data } = useSWR<Page<QAR>>(url);

  return [data as Page<QAR>];
};

export const useQAR = (id?: number): QAR => {
  let url: string | null = `${Api.baseUrl}${Api.qars.findAll}/${id}`;
  if (!id) {
    url = null;
  }
  const { data } = useSWR<QAR>(url);

  return data as QAR;
};

export const useLatestQAR = (qar?: string): QAR => {
  let url: string | null = qar
    ? `${Api.baseUrl}${Api.qars.findAll}/latest/${qar}`
    : null;
  const { data } = useSWR<QAR>(url);
  return data as QAR;
};

export const useQARSchemas = (id?: number): Schema => {
  let url: string | null = `${Api.baseUrl}${Api.qars.findAll}/${id}/schema`;
  if (!id) {
    url = null;
  }
  const { data } = useSWR<Schema>(url);

  return data as Schema;
};

export const useQARPreviewId = () => {
  return React.useCallback(
    async (qarId: number): Promise<number> =>
      rawFetcher(`${Api.baseUrl}/webportal/link/${qarId}`, {
        method: 'GET',
      })
        .then((r) => r.json())
        .then((r) => r.id),
    []
  );
};

export const useCreateOrUpdateQAR = () => {
  return React.useCallback(
    async (qar: Partial<QAR>): Promise<QAR> =>
      rawFetcher(`${Api.baseUrl}${Api.qars.findAll}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(qar),
      }).then((r) => r.json()),
    []
  );
};

export const usePullRequestQAR = () => {
  return React.useCallback(
    (qarId: number, env: string) =>
      rawFetcher(`${Api.baseUrl}${Api.qars.pullRequest(qarId)}?env=${env}`, {
        method: 'GET',
      }).then((res) => {
        if (res.status === 200) {
          return;
        }
        throw new Error(res.statusText);
      }),
    []
  );
};

export const useDeleteQAR = () =>
  React.useCallback(
    (qar: Partial<QAR>) =>
      rawFetcher(`${Api.baseUrl}${Api.qars.findAll}/${qar.id}`, {
        method: 'DELETE',
      }),
    []
  );
