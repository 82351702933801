import React from 'react';

import cx from 'classnames';

import { Row } from 'components/Flex';
import { Text } from 'components/Text';

import { CalendarEntryEvent } from './utils';

import './CalendarEvent.scss';

interface CalendarEventProps {
  eventEntry: CalendarEntryEvent<any>;
  hovered?: CalendarEntryEvent<any>;
  onClick(entry: CalendarEntryEvent<any>, element: HTMLElement): void;
  onHover(entry?: CalendarEntryEvent<any>): void;
}
export const CalendarDateEvent = React.forwardRef(
  (props: CalendarEventProps, ref: any) => {
    const onEvtClick = (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation();
      props.onClick(props.eventEntry, evt.target as HTMLElement);
    };

    return (
      <Row
        className={cx(
          'calendar-event',
          props.eventEntry.position,
          props.hovered?.key === props.eventEntry.key && 'hovered'
        )}
        style={{ backgroundColor: props.eventEntry.color }}
        onClick={onEvtClick}
        onMouseEnter={() => props.onHover(props.eventEntry)}
        onMouseLeave={() => props.onHover(undefined)}
        ref={ref}
      >
        <Text className='calendar-event-name' ellipsis>
          {props.eventEntry.showName ? props.eventEntry.event.name : ''}
        </Text>
      </Row>
    );
  }
);

export const ShowMoreCalendarEvent = ({ count }: { count: number }) => {
  return (
    <Row className='calendar-event'>
      <Text className='calendar-event-name' ellipsis>
        {count} events more...
      </Text>
    </Row>
  );
};
