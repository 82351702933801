import React from 'react';

import { SpanProps } from './Commons';

import './Text.scss';

export const RawText = ({ children }: React.PropsWithChildren<{}>) => {
  return <>{children}</>;
};

type TextProps = SpanProps & {
  ellipsis?: boolean;
};
export const Text = React.forwardRef((props: TextProps, ref: any) => {
  const { ellipsis = false, className, children, ...rest } = props;

  return (
    <span
      {...rest}
      ref={ref}
      className={`${className} text-span ${ellipsis ? 'ellipsis' : ''}`}
    >
      {children}
    </span>
  );
});

type HighlightProps = SpanProps & {
  highlight: string | string[];
  highlightColour?: string;
  highlightTextColour?: string;
  highlightClassName?: string;
};
export const Highlight = React.forwardRef((props: HighlightProps, ref: any) => {
  const {
    highlight,
    highlightClassName = '',
    highlightColour = '#bf7185',
    highlightTextColour = '#f0dfde',
    children,
    ...spanProps
  } = props;

  const chunks = highlighter(children as string, highlight);

  return (
    <Text {...spanProps} ref={ref}>
      {chunks.map(({ chunk, highlighted }, i) => {
        return highlighted ? (
          <span
            className={`text-highlight ${highlightClassName}`}
            key={i}
            style={{
              backgroundColor: highlightColour,
              color: highlightTextColour,
            }}
          >
            {chunk}
          </span>
        ) : (
          chunk
        );
      })}
    </Text>
  );
});

export type HighlightChunk = {
  chunk: string;
  highlighted: boolean;
};

const escapeRegex = (value: string) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
};

export const highlighter = (
  value: string,
  _highlight: string | string[]
): HighlightChunk[] => {
  if (_highlight == null) {
    return [{ chunk: value, highlighted: false }];
  }

  if ('string' !== typeof value) {
    return [];
  }

  const highlight = Array.isArray(_highlight)
    ? _highlight.map(escapeRegex)
    : escapeRegex(_highlight);

  const shouldHighlight = Array.isArray(highlight)
    ? highlight.filter((part) => part.trim().length > 0).length > 0
    : highlight.trim() !== '';

  if (!shouldHighlight) {
    return [{ chunk: value, highlighted: false }];
  }

  const matcher =
    typeof highlight === 'string'
      ? highlight.trim()
      : highlight
          .filter((part) => part.trim().length !== 0)
          .map((part) => part.trim())
          .join('|');

  const re = new RegExp(`(${matcher})`, 'gi');
  const chunks = value
    .split(re)
    .map((part) => ({ chunk: part, highlighted: re.test(part) }))
    .filter(({ chunk }) => chunk);

  return chunks;
};
