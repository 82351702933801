import React from 'react';

import { Loader, Icon } from 'tabler-icons-react';

import { ButtonProps } from './Commons';

import './Button.scss';
import { Link } from 'react-router-dom';

export const Button = React.forwardRef((props: ButtonProps, ref: any) => {
  const { className = '', ...otherProps } = props;

  return (
    <button {...otherProps} ref={ref} className={`button ${className}`}>
      {props.children}
    </button>
  );
});

export const TextButton = React.forwardRef((props: ButtonProps, ref: any) => {
  const { className = '', ...otherProps } = props;
  return (
    <Button className={`text-button ${className}`} ref={ref} {...otherProps} />
  );
});

export const LoadingButton = React.forwardRef(
  (props: ButtonProps & { loading?: boolean }, ref: any) => {
    const { className, loading, disabled = false, ...otherProps } = props;

    return (
      <ButtonWithIcon
        disabled={disabled || loading}
        ref={ref}
        icon={loading ? <Loader className='button-loading-indicator' /> : null}
        className={`loading-button ${className || ''}`}
        {...otherProps}
      >
        {props.children}
      </ButtonWithIcon>
    );
  }
);

export const ButtonWithIcon = React.forwardRef(
  (
    props: ButtonProps & {
      icon?: React.ReactNode;
      iconSide?: 'left' | 'right';
    },
    ref: any
  ) => {
    const { className, icon, iconSide = 'right', ...otherProps } = props;

    return (
      <Button
        ref={ref}
        className={`button-w-icon icon-${iconSide} ${className || ''}`}
        {...otherProps}
      >
        {iconSide === 'right' ? (
          <>
            {props.children}
            {icon}
          </>
        ) : (
          <>
            {icon}
            {props.children}
          </>
        )}
      </Button>
    );
  }
);

type ActionIconProps = ButtonProps & { rounded?: boolean };
export const ActionIcon = React.forwardRef(
  (props: ActionIconProps, ref: any) => {
    const { className = '', children, rounded = true, ...rest } = props;
    return (
      <Button
        {...rest}
        className={`action-icon-button ${
          rounded ? 'rounded' : ''
        } ${className}`}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

type LinkButtonProps = ButtonProps & { to: string; state?: any };
export const LinkButton = React.forwardRef(
  (props: LinkButtonProps, ref: any) => {
    const { className = '', children, to, state, ...rest } = props;

    return (
      <Link className={`link-button-link ${className}`} to={to} state={state}>
        <Button className='link-button' {...rest} ref={ref}>
          {children}
        </Button>
      </Link>
    );
  }
);
