import {
  SearchTokenOperatorMap,
  SearchTokenResult,
  SearchTokenValueType,
} from './models';

const exists = (val: any) => val !== null && val !== undefined;

export const tokensToApiFilter = (tokens: SearchTokenResult[]) => {
  let filters = [];

  for (let token of tokens) {
    const { code, operator, value } = token;

    if (token.token.type !== SearchTokenValueType.QuickFilter) {
      if (exists(code) && exists(operator) && exists(value)) {
        filters.push(
          `[${SearchTokenOperatorMap[operator!]}][${code}]${value!.value}`
        );
      }
    } else {
      if (exists(value)) {
        filters.push(`${value!.value}`);
      }
    }
  }

  return filters;
};
