import React from 'react';

import { isSameMonth, isToday } from 'date-fns';
import cx from 'classnames';

import { Group, Row } from 'components/Flex';
import { Text } from 'components/Text';

import { CalendarDateEvent, ShowMoreCalendarEvent } from './CalendarEvent';

import { CalendarEntry, CalendarEntryEvent } from './utils';

import './CalendarDay.scss';

interface CalendarDayProps {
  dateEntry: CalendarEntry<any>;
  date: Date;
  hoveredEvent?: CalendarEntryEvent<any>;
  onEventHover(event?: CalendarEntryEvent<any>): void;
  onEventClick(
    date: Date,
    event: CalendarEntryEvent<any>,
    element: HTMLElement
  ): void;
  onDayClick(
    date: Date,
    dayEntry: CalendarEntry<any>,
    element: HTMLElement
  ): void;
}
export const CalendarDay = (props: CalendarDayProps) => {
  const inCurrentMonth = isSameMonth(props.date, props.dateEntry.date);
  const today = isToday(props.dateEntry.date);

  const onDayClick = (evt: React.MouseEvent<HTMLElement>) => {
    props.onDayClick(
      props.dateEntry.date,
      props.dateEntry,
      evt.target as HTMLElement
    );
  };

  const onEventClick = (evt: CalendarEntryEvent<any>, element: HTMLElement) => {
    props.onEventClick(props.dateEntry.date, evt, element);
  };

  return (
    <Group
      className={cx(
        'calendar-day',
        !inCurrentMonth && 'disabled',
        today && 'today'
      )}
      orientation='column'
      spacing='2px'
      onClick={onDayClick}
    >
      <Row className='calendar-date-wrapper'>
        <Text className='calendar-date'>{props.dateEntry.date.getDate()}</Text>
      </Row>
      <CalendarDayEvents
        events={props.dateEntry.events}
        hovered={props.hoveredEvent}
        onEventHover={props.onEventHover}
        onEventClick={onEventClick}
      />
    </Group>
  );
};

interface CalendarDayEventsProps {
  events: CalendarEntryEvent<any>[];
  hovered?: CalendarEntryEvent<any>;
  onEventHover(event?: CalendarEntryEvent<any>): void;
  onEventClick(event: CalendarEntryEvent<any>, element: HTMLElement): void;
}
const CalendarDayEvents = (props: CalendarDayEventsProps) => {
  const showMore = props.events.length > 2;
  return (
    <Group orientation='column' spacing='1px' className='calendar-date-events'>
      {[...props.events]
        .reverse()
        .slice(0, 2)
        .map((evt) => (
          <CalendarDateEvent
            eventEntry={evt}
            key={evt.key}
            hovered={props.hovered}
            onClick={props.onEventClick}
            onHover={props.onEventHover}
          />
        ))}
      {showMore && <ShowMoreCalendarEvent count={props.events.length - 2} />}
    </Group>
  );
};
