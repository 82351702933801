import { createActionCreator } from 'deox';

import { Notification, StoreModal } from 'domain/models';

export const PUSH_NOTIFICATION = '[Root] :: Add notification';

export const REMOVE_NOTIFICATION = '[Root] :: Remove Notification';

export const PUSH_MODAL = '[Root] :: Push modal';

export const POP_MODAL = '[Root] :: Pop modal';

export const pushNotification = createActionCreator(
  PUSH_NOTIFICATION,
  (resolve) => (notification: Omit<Notification, 'innerKey'>) =>
    resolve(notification)
);

export const removeNotification = createActionCreator(
  REMOVE_NOTIFICATION,
  (resolve) => (key: number) => resolve(key)
);

export const pushModal = createActionCreator(
  PUSH_MODAL,
  (resolve) => (modal: StoreModal) => resolve({ modal })
);

export const popModal = createActionCreator(
  POP_MODAL,
  (resolve) => () => resolve()
);
