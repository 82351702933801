import { mergeRefs } from 'domain/utils';
import React from 'react';

import './Ripple.scss';

export interface RippleProps {
  /**
   * This className is not intended to be used directly.
   * It is a necessary property for the parent component
   * to pass the className to the child when this is done
   * implicitly. For example, when rendering a Ripple inside
   * a Group, the Group component must apply a className to
   * the child of the Ripple, not to the Ripple itself,
   * because Ripple does not render anything but the child.
   * ```
   * <Group> -> This component implicitly applies the '.group-child' class to its children. This class must reach the Button component, not the Ripple.
   *  <Ripple>
   *    <Button />
   *  </Ripple>
   * </Group>
   * ```
   */
  className?: string;
  colour?: string;
  duration?: number;
}
export const Ripple = React.forwardRef(
  (
    {
      className = '',
      colour = '#ffffff99',
      duration = 350,
      children,
    }: React.PropsWithChildren<RippleProps>,
    ref: any
  ) => {
    const child = React.Children.only(children);

    if (!React.isValidElement(child)) {
      return child;
    }

    const intOnClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (React.isValidElement(child)) {
        const childOnClick = child.props.onClick;
        const target = evt.currentTarget;
        const circle = document.createElement('span');
        const diameter = Math.max(target.clientWidth, target.clientHeight);
        const radius = diameter / 2;
        circle.style.width = circle.style.height = `${diameter}px`;

        const { top, left } = target.getBoundingClientRect();

        const _left = evt.clientX - left - radius;
        const _top = evt.clientY - top - radius;

        circle.style.left = `${_left}px`;
        circle.style.top = `${_top}px`;

        circle.style.animationDuration = `${duration}ms`;
        circle.style.backgroundColor = colour;

        circle.classList.add('ripple');

        const ripple = target.getElementsByClassName('ripple')[0];

        if (ripple) {
          ripple.remove();
        }

        target.appendChild(circle);

        if (childOnClick) {
          childOnClick(evt);
        }
      }
    };

    return React.cloneElement(child as any, {
      className: child.props.className + className ?? '',
      onClick: intOnClick,
      ref: mergeRefs(child.props.ref, ref),
      style: {
        ...child.props?.style,
        position: 'relative',
        overflow: 'hidden',
      },
    }) as any;
  }
);
