import { useEffect, useState } from 'react';

export const useWordCount = (value: any | null | undefined) => {
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    if (value) {
      const asString = value.toString();
      setWordCount(asString.split(' ').length);
    } else {
      setWordCount(0);
    }
  }, [value]);

  return wordCount;
};
