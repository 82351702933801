import React from 'react';

export interface SwitchProps {
  value: string | number;
}
export const Switch = ({
  value,
  children,
}: React.PropsWithChildren<SwitchProps>) => {
  const testCase = React.Children.toArray(children).find(
    (child: any) =>
      child && child.type === SwitchCase && child.props.caseValue === value
  );

  if (testCase) {
    return (React.isValidElement(testCase)
      ? React.cloneElement(testCase)
      : testCase) as React.ReactElement;
  }

  const defaultCase = React.Children.toArray(children).find(
    (child: any) => child.type === SwitchDefault
  );

  if (defaultCase) {
    return (React.isValidElement(defaultCase)
      ? React.cloneElement(defaultCase)
      : defaultCase) as React.ReactElement;
  }

  return null;
};

export const SwitchCase = ({
  caseValue,
  children,
}: React.PropsWithChildren<{ caseValue: string | number }>) => {
  return (
    (React.Children.map(children, (child, index) =>
      React.isValidElement(child)
        ? React.cloneElement(child, { key: index })
        : child
    ) as any) || null
  );
};

export const SwitchDefault = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    (React.Children.map(children, (child, index) =>
      React.isValidElement(child)
        ? React.cloneElement(child, { key: index })
        : child
    ) as any) || null
  );
};
