import React from 'react';

import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  DeviceFloppy,
  Eye,
  EyeOff,
  GridDots,
  Refresh,
  Settings,
} from 'tabler-icons-react';

import { ActionIcon, ButtonWithIcon } from 'components/Button';
import { Group, Row } from 'components/Flex';
import { SecurePortal } from 'components/SecurePortal';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { ListWrapperContext } from './ListComponents';

import { HeaderField } from './ListHeader';

import './ListConfiguration.scss';
import { Tooltip } from 'components/Tooltip';

interface ListConfigurationManagerProps {
  fields?: HeaderField[];
  onHeaderEdit(newFields: HeaderField[]): void;
  onReset: VoidFunction;
}
export const ListConfigurationManager = (
  props: ListConfigurationManagerProps
) => {
  const { editable, fields } = React.useContext(ListWrapperContext);

  const finalFields = React.useMemo(
    () => props.fields ?? fields ?? [],
    [fields, props.fields]
  );

  const [showModal, setShowModal] = React.useState(false);

  return editable ? (
    <ListConfigurationWrapper>
      <ListConfigurationButton onClick={() => setShowModal(true)} />
      {showModal && (
        <ListConfigurationModal
          fields={finalFields}
          onHeaderEdit={props.onHeaderEdit}
          onClose={() => setShowModal(false)}
          onReset={props.onReset}
        />
      )}
    </ListConfigurationWrapper>
  ) : null;
};

interface ListConfigurationButtonProps {
  className?: string;
  onClick: VoidFunction;
}
export const ListConfigurationButton = (
  props: ListConfigurationButtonProps
) => {
  return (
    <Tooltip content='Show list configuration'>
      <ActionIcon
        className={`list-configuration-button ${props.className ?? ''}`}
        onClick={props.onClick}
      >
        <Settings strokeWidth={2} className='settings-icon' />
      </ActionIcon>
    </Tooltip>
  );
};

interface ListConfigurationModalProps {
  fields: HeaderField[];
  onHeaderEdit(newFields: HeaderField[]): void;
  onReset: VoidFunction;
  onClose: VoidFunction;
}
const ListConfigurationModal = (props: ListConfigurationModalProps) => {
  const [localFields, setLocalFields] = React.useState(props.fields);

  React.useEffect(() => setLocalFields(props.fields), [props.fields]);

  const items = React.useMemo(
    () => localFields.map((f) => f.code),
    [localFields]
  );

  const onDragEnd = (event: DragEndEvent) => {
    if (event.over) {
      const newId = event.over.id;
      const activeId = event.active.id;

      const newIdx = localFields.findIndex((f) => f.code === newId);
      const activeIdx = localFields.findIndex((f) => f.code === activeId);

      setLocalFields((f) => arrayMove(f, activeIdx, newIdx));
    }
  };

  const changeVisibility = (field: HeaderField) => {
    setLocalFields((fields) =>
      fields.map((f) => (f.code === field.code ? field : f))
    );
  };

  const submit = () => {
    props.onHeaderEdit(localFields);
    props.onClose();
  };

  const reset = () => {
    props.onReset();
    props.onClose();
  };

  return (
    <Modal
      header={<Text>Edit list columns</Text>}
      footer={
        <Group placement='end'>
          <ButtonWithIcon icon={<Refresh strokeWidth={1} />} onClick={reset}>
            Restore
          </ButtonWithIcon>
          <ButtonWithIcon
            icon={<DeviceFloppy strokeWidth={1} />}
            onClick={submit}
          >
            Save
          </ButtonWithIcon>
        </Group>
      }
      onClose={props.onClose}
      dismissable
    >
      <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
        <Group orientation='column' spacing='0px'>
          <SortableContext items={items}>
            {localFields.map((field) => (
              <HeaderFieldListItem
                field={field}
                key={field.code}
                onVisibilityChange={changeVisibility}
              />
            ))}
          </SortableContext>
        </Group>
      </DndContext>
    </Modal>
  );
};

type HeaderFieldListItemProps = {
  field: HeaderField;
  onVisibilityChange(field: HeaderField): void;
};
const HeaderFieldListItem = (props: HeaderFieldListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.field.code });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    transformOrigin: 'center center',
  };

  const VisibilityIcon = React.useCallback(() => {
    const onClick = (evt: any) => {
      evt.stopPropagation();
      props.onVisibilityChange({
        ...props.field,
        visible: props.field.visible === false ? true : false,
      });
      return false;
    };

    const iconProps = {
      size: 18,
      strokeWidth: 1,
      className: 'visibility-icon',
      onClick,
    };

    return props.field.visible !== false ? (
      <Eye {...iconProps} />
    ) : (
      <EyeOff {...iconProps} />
    );
  }, [props.field]);

  return (
    <Group
      className={`field-list-item ${isDragging ? 'dragging' : ''} ${
        props.field.visible === false ? 'disabled' : ''
      }`}
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      <Group placement='start'>
        <Text className='field-name'>{props.field.label}</Text>
      </Group>
      <Group placement='end' spacing='8px'>
        <VisibilityIcon />
        <Row ref={setActivatorNodeRef} {...listeners}>
          <GridDots className='drag-icon' strokeWidth={1} size={18} />
        </Row>
      </Group>
    </Group>
  );
};

const ListConfigurationWrapper = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { id } = React.useContext(ListWrapperContext);

  return (
    <SecurePortal id={`list-configuration-wrapper_${id}`}>
      {children}
    </SecurePortal>
  );
};
