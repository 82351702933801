import { ApiError } from 'domain/models/api.model';
import { AuthorizedService } from 'domain/services/authorized-service.base';

export class SwrFetcher extends AuthorizedService {
  public fetcher(url: string, init?: RequestInit | undefined) {
    return this.fetch(url, init).then((r) => {
      if (ApiError.isFailedRequest(r)) {
        throw ApiError.fromResponse(r);
      }
      return r.json();
    });
  }

  public rawFetcher(url: string, init?: RequestInit | undefined) {
    return this.fetch(url, init);
  }
}

const instance = new SwrFetcher();
export const fetcher = (url: string, init?: RequestInit | undefined) =>
  instance.fetcher(url, init);
export const rawFetcher = (url: string, init?: RequestInit | undefined) =>
  instance.rawFetcher(url, init);
