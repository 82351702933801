import useSWR from 'swr';

import { Page, PageQuery, Role, RoleMap } from 'domain/models';
import { Api } from 'domain/core';
import { PagedQueryParams } from 'domain/utils';

export const useRoles = (
  page: PageQuery = { page: 0, size: 10 },
) => {
  const qs = new PagedQueryParams(page).getQueryParams();
  
  const { data } = useSWR(`${Api.baseUrl}${Api.admin.workflow_roles}?${qs}`);

  return data as Page<Role> | undefined;
};

export const useRole = (role?: Role): RoleMap => {
  let url: string | null = `/mock/role.json`;

  if (!role) {
    url = null;
  }

  const { data } = useSWR<RoleMap>(url);

  return data as RoleMap;
};
