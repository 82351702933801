import React from 'react';

import { ChevronDown, ChevronUp, Filter } from 'tabler-icons-react';

import { Grid, GridItem, ListWrapperContext, Row, Tooltip } from 'components';

import './ListHeader.scss';

export interface SortInfo {
  sortField: string;
  sortDir: 'ASC' | 'DESC';
}

export type HeaderField = {
  label: string | React.ReactElement;
  code: string;
  sortable: boolean;
  col: number;
  className?: string;
  visible?: boolean;
};

export type HeaderRenderer = (
  field: HeaderField,
  index: number
) => React.ReactElement | any;

const getFilterHeaderField = (onClick: VoidFunction): HeaderField => ({
  code: '__filter-icon__',
  col: 1,
  label: (
    <Tooltip content='Toggle filter' placement='bottom'>
      <span onClick={onClick} className='filter-icon-wrapper'>
        <Filter strokeWidth={2} className='filter-icon' />
      </span>
    </Tooltip>
  ),
  sortable: false,
  visible: true,
  className: 'list-header-filter-col',
});

interface ListHeaderProps {
  fields?: HeaderField[];
  compact?: boolean;
  sortField?: SortInfo['sortField'];
  sortDir?: SortInfo['sortDir'];
  className?: string;
  onSortChange(sortInfo: SortInfo): void;
  headerRenderer?: HeaderRenderer;
}
export const ListHeader = ({
  fields,
  compact,
  sortDir,
  sortField,
  className = '',
  onSortChange,
  headerRenderer,
}: ListHeaderProps) => {
  const {
    filterable,
    setShowFilter,
    showFilter,
    fields: ctxFields,
  } = React.useContext(ListWrapperContext);

  const intOnSortChange = (field: HeaderField) => {
    if (field.sortable) {
      if (sortField === field.code) {
        if (sortDir === 'ASC') {
          onSortChange({ sortField: field.code, sortDir: 'DESC' });
        } else {
          onSortChange({ sortField: field.code, sortDir: 'ASC' });
        }
      } else {
        onSortChange({ sortField: field.code, sortDir: 'ASC' });
      }
    }
  };

  const SortIcon = ({ field }: { field: HeaderField }) => {
    if (field.sortable && field.code === sortField) {
      if (sortDir === 'ASC') {
        return (
          <ChevronDown strokeWidth={1} className='list-header-field-icon' />
        );
      } else {
        return <ChevronUp strokeWidth={1} className='list-header-field-icon' />;
      }
    }
    return null;
  };

  const finalFields = React.useMemo(() => {
    const baseFields = fields ?? ctxFields ?? [];
    return filterable && setShowFilter
      ? baseFields.concat(
          getFilterHeaderField(() => setShowFilter(!showFilter))
        )
      : baseFields;
  }, [fields, filterable, setShowFilter, showFilter, ctxFields]);

  const cols = React.useMemo(() => {
    return finalFields
      .filter((f) => f.visible !== false)
      .reduce((acc: number, curr: HeaderField) => acc + curr.col, 0);
  }, [finalFields]);

  const HeaderContent = ({
    field,
    index,
  }: {
    field: HeaderField;
    index: number;
  }) => {
    let content;
    if (headerRenderer) {
      content = headerRenderer(field, index);
    }

    if (content) {
      return content;
    } else {
      return (
        <React.Fragment>
          <span className='list-header-field-name'>{field.label}</span>
          <SortIcon field={field} />
        </React.Fragment>
      );
    }
  };

  return (
    <Grid className={`list-header-grid ${className} 
      ${compact ? 'compact' : ''}
    `} columns={cols}>
      {finalFields
        .filter((h) => h.visible !== false)
        .map((field, index) => (
          <GridItem
            key={field.code}
            className={`list-header-field-container ${field.code} ${
              field.className ?? ''
            } ${field.code === sortField ? 'active' : ''} ${
              field.sortable ? 'sortable' : ''
            }`}
            col={field.col}
          >
            <Row
              className='list-header-field'
              onClick={() => intOnSortChange(field)}
              title={'string' === typeof field.label ? field.label : ''}
            >
              <HeaderContent field={field} index={index} />
            </Row>
          </GridItem>
        ))}
    </Grid>
  );
};
