import React from "react";

import { Column, Grid, GridItem, Row } from "components";

import { HeaderField } from "./ListHeader";
import { DefaultCellValue, ListWrapperContext } from "./ListComponents";

import "./List.scss";

export type ListElementRenderer = (
  field: HeaderField,
  element: any,
  index: number
) => React.ReactElement | any;

export type RowClickCallback = (element: any, index: number) => void;

interface ListProps {
  fields?: HeaderField[];
  data?: any[];
  className?: string;
  scrollable?: boolean;
  decorator?: boolean;
  compact?: boolean;
  columnRenderer?: ListElementRenderer;
  onRowClick?: RowClickCallback;
}
/**
 * List component
 * @param
 * @returns
 */
export const List = ({
  data,
  scrollable,
  fields,
  className = "",
  compact = false,
  columnRenderer,
  onRowClick,
}: ListProps) => {
  const {
    id,
    fields: ctxFields,
    dataPage,
  } = React.useContext(ListWrapperContext);

  const finalData = React.useMemo(
    () => data ?? dataPage?.content ?? [],
    [data, dataPage]
  );
  const finalFields = React.useMemo(
    () => (fields ?? ctxFields) as HeaderField[],
    [fields, ctxFields]
  );

  return (
      <Column
        className={`list ${className} ${compact ? "compact" : ""}  ${
          scrollable ? "scrollable" : ""
        }`}
      >
        <div
          className={`no-elements-container ${
            finalData?.length > 0 ? "hide" : "show"
          }`}
          id={`${id}-no-elements-container`}
        />
        {finalData &&
          finalData.map((d, i) => (
            <ListElement
              key={i}
              index={i}
              element={d}
              fields={finalFields}
              onClick={onRowClick}
              renderer={columnRenderer}
            />
          ))}
      </Column>
  );
};

interface ListElementProps {
  element: any;
  fields: HeaderField[];
  index: number;
  renderer?: ListElementRenderer;
  onClick?: RowClickCallback;
}
/**
 * List element responsible of render each list row
 * @param
 * @returns
 */
export const ListElement = ({
  element,
  fields,
  index,
  renderer,
  onClick,
}: ListElementProps) => {
  const { filterable } = React.useContext(ListWrapperContext);

  const ElementContent = ({
    field,
    element,
    index,
  }: {
    field: HeaderField;
    element: any;
    index: number;
  }): React.ReactElement => {
    let content;
    if (renderer) {
      content = renderer(field, element, index);
    }
    if (content) {
      return content;
    } else {
      return (
        <DefaultCellValue title={element[field.code]}>
          {element[field.code] ?? ""}
        </DefaultCellValue>
      );
    }
  };

  const cols = React.useMemo(() => {
    return (
      fields
        .filter((f) => f.visible !== false)
        .reduce((acc: number, curr: HeaderField) => acc + curr.col, 0) +
      (filterable ? 1 : 0)
    );
  }, [fields, filterable]);

  const intOnClick = () => {
    if (onClick) {
      onClick(element, index);
    }
  };

  return (
    <Row
      className={`list-element-row ${onClick ? "interactive" : ""}`}
      onClick={intOnClick}
    >
      <Grid className="list-element-grid" columns={cols}>
        {fields
          .filter((f) => f.visible !== false)
          .map((field, i) => (
            <GridItem
              className={`element-cell ${field.className ?? ""} ${field.code}`}
              col={field.col}
              key={field.code}
            >
              <ElementContent field={field} element={element} index={i} />
            </GridItem>
          ))}
      </Grid>
    </Row>
  );
};
