import React from 'react';

import { Row, Group } from 'components/Flex';
import { Text } from 'components/Text';

import { OperatorIcon } from './Commons';

import {
  SearchToken,
  SearchTokenResult,
  SearchTokenOperator,
  SearchTokenValueType,
  FixedSearchToken,
} from './models';

import './TokenBlock.scss';

interface TokenFieldProps {
  token: SearchToken;
  tokenValue: SearchTokenResult;
  index: number;
  onRemoveBlock: VoidFunction;
}
export const TokenItem = ({
  token,
  tokenValue,
  onRemoveBlock,
}: TokenFieldProps) => {
  const FieldToken = React.useCallback(() => {
    return tokenValue.code ? (
      <FieldTokenBlock>
        <Text ellipsis>{token.label}</Text>
      </FieldTokenBlock>
    ) : null;
  }, [token, tokenValue]);

  const OperatorToken = React.useCallback(() => {
    return tokenValue.operator &&
      token.type !== SearchTokenValueType.QuickFilter ? (
      <OperatorTokenBlock operator={tokenValue.operator} />
    ) : null;
  }, [token, tokenValue]);

  const ValueToken = React.useCallback(() => {
    if (tokenValue.value && token.type !== SearchTokenValueType.QuickFilter) {
      return (
        <ValueTokenBlock>
          <Text ellipsis>{tokenValue.value.label}</Text>
        </ValueTokenBlock>
      );
    }
    return null;
  }, [token, tokenValue]);

  return (
    <Group
      className='token-group'
      placement='start'
      spacing='2px'
      onClick={onRemoveBlock}
      title='Remove filter entry'
    >
      <FieldToken />
      <OperatorToken />
      <ValueToken />
    </Group>
  );
};

interface TokenBlockProps extends React.PropsWithChildren {
  className?: string;
  onClick?: VoidFunction;
}
const TokenBlock = ({ children, className = '', onClick }: TokenBlockProps) => {
  return (
    <Row className={`token-block ${className}`} onClick={onClick}>
      {children}
    </Row>
  );
};

const FieldTokenBlock = ({ className = '', children }: TokenBlockProps) => {
  return (
    <TokenBlock className={`field-block ${className}`}>{children}</TokenBlock>
  );
};

const OperatorTokenBlock = ({
  className = '',
  operator,
}: TokenBlockProps & { operator: SearchTokenOperator }) => {
  const iconProps = {
    className: 'operator-icon',
    size: 18,
    strokeWidth: 1,
  };
  return (
    <TokenBlock className={`operator-block ${className}`}>
      <OperatorIcon operator={operator} {...iconProps} />
    </TokenBlock>
  );
};

const ValueTokenBlock = ({ className = '', children }: TokenBlockProps) => {
  return (
    <TokenBlock className={`value-block ${className}`}>{children}</TokenBlock>
  );
};
