import React from 'react';
import { InputProps } from './Commons';

import './FileInput.scss';
import { Group, Row } from './Flex';

interface FileInputProps extends InputProps {
  files?: string[] | File[];
  multiple?: boolean;
  onFileDrop(files: File[]): void;
}
export const FileInput = ({
  onFileDrop,
  files,
  className,
  children,
  multiple,
  disabled,
  ...nativeProps
}: FileInputProps) => {
  const dropRef = React.useRef<HTMLDivElement>(null);

  const [drag, setDrag] = React.useState(false);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    if (!disabled) {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.items && e.dataTransfer.items.length === 1)
        setDrag(true);
    }
  };

  const handleDragOut = (e: React.DragEvent) => {
    if (!disabled) {
      e.preventDefault();
      e.stopPropagation();
      setDrag(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    if (!disabled) {
      e.preventDefault();
      e.stopPropagation();
      setDrag(false);
      if (e.dataTransfer.files) {
        onFileDrop(Array.from(e.dataTransfer.files));
        e.dataTransfer.clearData();
      }
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && !disabled) {
      onFileDrop(Array.from(e.target.files));
    }
  };

  return (
    <Row
      // @ts-ignore
      ref={dropRef}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={`file-input ${drag ? 'dragging' : ''} ${
        files?.length !== 0 ? 'has-file' : ''
      } ${className || ''}`}
      {...nativeProps}
    >
      <input
        type='file'
        className='hidden-input'
        multiple={multiple}
        disabled={disabled}
        onChange={handleFileInput}
      />
      {children ? (
        children
      ) : files?.length !== 0 && !drag ? (
        <Group
          orientation='column'
          placement='start'
          spacing='0.5em'
          className='file-list'
        >
          {files?.map((f) => (
            <Filename file={f} key={'string' === typeof f ? f : f.name} />
          ))}
        </Group>
      ) : (
        <div className='file-input-hint'>Click or drag and drop files</div>
      )}
    </Row>
  );
};

const Filename = ({ file }: { file: string | File }) => (
  <div className='file-input-filename'>
    {'string' === typeof file ? file : file.name}
  </div>
);
