import React from 'react';

import { ErrorSchema, FieldProps } from '@rjsf/utils';
import { ActionMeta, OnChangeValue } from 'react-select';
import set from 'lodash.set';
import get from 'lodash.get';

export const useSelectSchemaOptions = (props: FieldProps) => {
  return React.useMemo(() => {
    if (props.schema.enum) {
      return props.schema.enum.map((e, i) => {
        const schema = props.schema as any;
        const eName = schema.enumNames ? schema.enumNames[i] : e;
        return {
          value: e,
          label: eName,
        };
      });
    } else if ((props.schema.items as any)?.enum) {
      const items = props.schema.items as any;
      return items.enum.map((a: any, i: number) => {
        const label = items.enumNames ? items.enumNames[i] : a;
        return {
          label,
          value: a,
        };
      })!;
    }
  }, [props]);
};

export const getRawErrors = (
  errorsSchema: ErrorSchema = {},
  subSchema: any
): Record<string, string[]> => {
  let result: Record<string, string[]> = {};
  const properties = subSchema.properties ?? {};
  for (let k of Object.keys(properties)) {
    if (properties.hasOwnProperty(k)) {
      const err = errorsSchema[k];

      if (err) {
        result[k] = (err as any).__errors as string[];
      }
    }
  }

  return result;
};

export const useSelectOnChange = (props: FieldProps) => {
  return React.useCallback(
    (newValue: OnChangeValue<any, boolean>, actionMeta: ActionMeta<any>) => {
      const { action, option, removedValue } = actionMeta;
      const isMulti = props.uiSchema?.multiple;

      if (!isMulti) {
        props.onChange(newValue.value);
      } else {
        const currValue = props.formData ?? [];
        switch (action) {
          case 'clear': {
            props.onChange(undefined);
            break;
          }

          case 'create-option':
          case 'select-option': {
            const opts = currValue;
            const newOptions = opts
              .concat(option.value)
              .filter((o: any) => !!o);
            props.onChange(newOptions);
            break;
          }

          case 'remove-value':
          case 'deselect-option': {
            const opts = currValue;
            const newOptions = opts
              .filter((v: any) => v !== (option?.value ?? removedValue.value))
              .filter((o: any) => !!o);
            props.onChange(newOptions);
            break;
          }
        }
      }
    },
    [props]
  );
};

export const readOnlyFromProps = (props: {
  readonly?: boolean;
  disabled?: boolean;
  uiSchema?: any;
}) => {
  return (
    props.readonly ||
    props.disabled ||
    props.uiSchema?.['ui:readonly'] ||
    props.uiSchema?.['ui:disabled'] ||
    false
  );
};

export const useDefaulFormData = (initFormData: any, schema: any) => {
  return React.useMemo(() => {
    let tmpFormData = { ...initFormData };
    const traverse = (object: object, path: string[]) => {
      for (let [key, value] of Object.entries(object)) {
        let p = [...path];
        if ('object' === typeof value && !Array.isArray(value)) {
          const keys = p;
          const noProperties = keys.filter((k) => k !== 'properties');
          if (noProperties.includes('references')) {
            // Do nothing, as this can lead to broken references
          } else if (keys.length && key === 'properties') {
            console.log(
              'pushing',
              noProperties,
              get(tmpFormData, noProperties, {})
            );
            set(tmpFormData, noProperties, get(tmpFormData, noProperties, {}));
          }
          p.push(key);
          traverse(value, p);
        }
      }
    };

    traverse(schema.properties, []);

    return tmpFormData;
  }, [initFormData, schema]);
};
