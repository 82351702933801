import { format } from "date-fns";

export const formatDate = (date: Date | string | number) => {
  if (date === undefined) {
    return date;
  }
  const dateObj = new Date(date);
  return format(dateObj, 'MM/dd/yyyy');
}

export const formatTime = (date: Date | string | number) => {
  if (date === undefined) {
    return date;
  }
  const dateObj = new Date(date);
  return format(dateObj, 'hh:mm:ss');
}

export const formatDateTime = (date?: Date | string | number) => {
  if (date === undefined) {
    return date;
  }
  const dateObj = new Date(date);
  return format(dateObj, 'MM/dd/yyyy hh:mm:ss');
}

export class DateUnit {
  public static SECOND = new DateUnit(1000);
  public static MINUTE = new DateUnit(60 * 1000);
  public static HOUR = new DateUnit(60 * 60 * 1000);
  public static DAY = new DateUnit(24 * 60 * 60 * 1000);
  public static WEEKS = new DateUnit(7 * 24 * 60 * 60 * 1000);
  public static MONTH = new DateUnit(30 * 24 * 60 * 60 * 1000);

  ms : number = 1;
  constructor(ms: number) {
    this.ms = ms;
  }
  
  traslate(date: Date, value: number) : Date {
    return new Date(date.getTime() + value * this.ms);
  }
  
  atStart(date: Date): Date {
    return new Date(this.ms * Math.floor(date.getTime() / this.ms));
  }

  fill(from: Date, to: Date) : Date[] {
    const tsFrom = from.getTime();
    const tsTo = to.getTime();
    const values : Date[] = [];
    if (tsFrom >= tsTo) {
      return values;
    }
    for (let i = tsFrom; i < tsTo; i+= this.ms) {
      values.push(new Date(i));
    }
    return values;
  }

}
